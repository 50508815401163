import React, { useEffect, useRef, useState } from "react"
import { FEATURES } from "../../../../../lib/features"
import { useInView, useScroll } from "framer-motion"
import style from "./feature-list.module.scss"
import Feature from "../feature/Feature"
import FeaturePagination from "../feature-pagination/FeaturePagination"
import PropTypes from "prop-types"

FeaturesList.propTypes = {
    onSelectFeature: PropTypes.func.isRequired,
}

export default function FeaturesList({ onSelectFeature }) {
    const isMobile = window.innerWidth <= 768
    const voidHeight = 50 // Plus cette valeur va être grande plus chaque feature va rester affichée longtemps

    // Détecter si l'utilisateur voit actuellement la section feature
    const containerRef = useRef("featuresRef") // Référence de notre fonction (cf <div ref={containerRef]> dans le render
    const [currentFeature, setCurrentFeature] = useState(0) // Index de la feature à afficher
    const isInView = useInView(containerRef, { amount: 0.12 })

    useEffect(() => {
        if (!isInView) {
            // Quand on l'utilisateur ne voit plus la section, on réinitialise à null l'index de la feature à montrer
            setCurrentFeature(-1)
        }
    }, [isInView])

    // Effet quand on scroll (utilisation de position pour savoir où on en est)
    // la position commence quand le centre de la section se trouve au centre de l'écran
    const { scrollYProgress } = useScroll({ target: containerRef, offset: ["start center", "end center"] })
    const [position, setPosition] = useState(0)

    scrollYProgress.onChange((x) => {
        setPosition(x)
        const featurePosition = Math.floor(x * FEATURES.length)
        setCurrentFeature(featurePosition)
        onSelectFeature(FEATURES[currentFeature])
    })

    return (
        <div ref={containerRef} className={style.featureList}>
            <div className={style.bloctop}>
                <div
                    style={{ height: isMobile ? "45vh" : "40vh" }}
                    className={style.featurePagination + " d-flex align-items-center"}
                >
                    <FeaturePagination features={FEATURES} currentFeature={currentFeature} position={position} />
                </div>
                <div
                    style={{
                        height: isMobile ? "30vh" : "40vh",
                        zIndex: 1,
                        position: "absolute",
                        top: isMobile ? "50vh" : "40vh",
                        width: "100%",
                    }}
                >
                    {FEATURES.map((s) => (
                        <Feature
                            key={s.id}
                            id={s.id}
                            title={s.title}
                            description={s.description}
                            currentIdShow={currentFeature + 1}
                        />
                    ))}
                </div>
            </div>

            {FEATURES.map((s) => (
                // <Feature key={s.id} title={s.title} description={s.description}/>
                <div key={s.id} style={{ height: voidHeight + "rem" }} />
            ))}
        </div>
    )
}
