import React from "react"
import { useTranslation } from "react-i18next"
import { Col, Container, Row } from "react-bootstrap"
import style from "../footer.module.scss"
import styleFooterJoin from "./FooterJoin.module.scss"

export default function FooterJoin() {
    const { t } = useTranslation()
    return (
        <Container fluid={true}>
            <Row>
                <Col className={style.footerask}>
                    {[t("footer.join.title")]}
                    <span className="colorActive fw-normal">*</span>{" "}
                </Col>
            </Row>
            <Row>
                <Col className={style.footertext}>
                    <button className={styleFooterJoin.joinlink + " position-relative fw-semibold"}>
                        {[t("footer.join.link").toUpperCase()]}
                    </button>
                </Col>
            </Row>
        </Container>
    )
}
