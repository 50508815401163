import React from "react"
// Components
import FMScreen from "../components/animation/FMScreen/FMScreen"
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import PoliticFR from "../components/page-sections/politic/bylang/PoliticFR";
import PoliticEN from "../components/page-sections/politic/bylang/PoliticEN";
import PoliticTitle from "../components/page-sections/politic/title/PoliticTitle";

export default function PoliticPage() {

    const {i18n} = useTranslation();

    return (
        <div className="section-border-end pb-5 mb-5">
            <Container>
                <Row>
                    <Col><PoliticTitle /></Col>
                </Row>
                <Row>
                    <Col>
                        { i18n.language === "fr" && <PoliticFR /> }
                        { i18n.language === "en" && <PoliticEN /> }
                    </Col>
                </Row>
            </Container>

            <FMScreen/>
        </div>
    )
}
