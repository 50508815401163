import React from "react"

export default function EeccFR() {

    return (
        <>
            <p>Le code européen des communications électroniques (directive (UE) 2018/1972) nous oblige à fournir aux utilisateurs concernés les informations suivantes sur le service de communication électronique que nous fournissons dans le cadre de nos services de messagerie, y compris le chat, la voix et appels vidéo. Ces informations complètent les conditions d&#39;utilisation de ces services. Les utilisateurs concernés incluent les microentreprises ou les petites entreprises ou les organisations à but non lucratif, à moins qu&#39;ils n&#39;aient expressément accepté de renoncer à tout ou partie de ces dispositions.</p>
            <p>Aux fins de votre documentation, référence future et reproduction inchangée, vous pouvez télécharger ou imprimer une copie de ce document.</p>
            <h2 id="1-notre-qualit-de-service">1. Notre qualité de service</h2>
            <p>Bien que nous nous efforcions de vous fournir le meilleur service, nous ne pouvons garantir aucun niveau minimum de qualité de service et ne garantissons pas que nos services de messagerie fonctionneront toujours sans interruptions, retards ou erreurs.</p>
            <p>Un certain nombre de facteurs indépendants de notre volonté peuvent également affecter votre expérience d&#39;utilisation de nos services de messagerie et peuvent entraîner une interruption de votre communication avec d&#39;autres utilisateurs, y compris, mais sans s&#39;y limiter : votre réseau local, votre firewall, votre fournisseur de services Internet, réseau internet public et alimentation électrique.</p>
            <h2 id="2-prix">2. Prix</h2>
            <p>Nous ne facturons pas l&#39;utilisation de nos services de messagerie. Tous les utilisateurs de nos services de messagerie sont responsables des plans de données du fournisseur de réseau, des frais d&#39;accès à Internet et des autres frais et taxes associés à l&#39;utilisation du service.</p>
            <h2 id="3-dur-e">3. Durée</h2>
            <p>Nos utilisateurs peuvent mettre fin à leur relation avec notre service à tout moment pour n&#39;importe quelle raison en supprimant leur compte. Nous pouvons modifier, suspendre ou résilier votre accès ou votre utilisation de nos services de messagerie pour les raisons décrites dans les Conditions d&#39;utilisation de KEYM.</p>
            <h2 id="4-la-s-curit-que-nous-assurons-sur-nos-services-de-messagerie">4. La sécurité que nous assurons sur nos services de messagerie</h2>
            <p>Nous nous efforçons de protéger la sûreté, la sécurité et l&#39;intégrité de nos services de messagerie en traitant de manière appropriée les personnes abusives et les activités enfreignant nos conditions d&#39;utilisation. Nous nous efforçons d&#39;interdire l&#39;utilisation abusive de notre service, y compris les comportements préjudiciables envers les autres. Nous exploitons des systèmes pour améliorer notre capacité à détecter les incidents, les menaces ou les vulnérabilités susceptibles de nuire à la sécurité de notre service. Si nous prenons connaissance d&#39;une telle activité, nous prendrons les mesures appropriées, telles que la suppression de cette activité ou la prise de contact avec les forces de l&#39;ordre.</p>
            <h2 id="5-donn-es-fournies-par-vous">5. Données fournies par vous</h2>
            <p>Lorsque vous vous inscrivez à notre service KEYM en tant que nouvel utilisateur, nous vous demandons d&#39;indiquer votre date de naissance (afin que nous puissions offrir une expérience adaptée à votre âge) et votre adresse e-mail ou votre numéro de téléphone portable. Vous pouvez trouver plus d&#39;informations sur les données qui sont collectées et traitées par KEYM dans le cadre de la fourniture du service dans notre politique de données.</p>
            <h2 id="6-fonctionnalit-s-pour-les-utilisateurs-finaux-handicap-s">6. Fonctionnalités pour les utilisateurs finaux handicapés</h2>
            <p>Nos services de messagerie sont compatibles avec les fonctionnalités d&#39;accessibilité mobile, telles que la possibilité de régler diverses options de contraste, de couleurs et de taille de texte à l&#39;écran.</p>
            <h2 id="7-informations-sur-la-gestion-des-litiges">7. Informations sur la gestion des litiges</h2>
            <p>Si vous n&#39;êtes pas satisfait de nos services de messagerie, vous pouvez déposer une plainte en nous écrivant à :</p>
            <p>Gonly Sàrl
                17, route d&#39;Esch
                L-1470 Luxembourg
                G-D du Luxembourg
                Courriel : hello@keym-music.com</p>
            <p>Vous pouvez également signaler un problème lié à votre utilisation de KEYM en le signalant en nous contactant à l&#39;adresse ci-dessus.</p>
            <p>Vous pouvez intenter une action en justice contre nous pour toute réclamation découlant de ou liée à nos services de messagerie. Pour plus d&#39;informations sur la loi applicable et le lieu qui s&#39;applique à votre réclamation, veuillez vous reporter à la section « Désaccord et différends » des conditions d&#39;utilisation de KEYM.</p>
            <p>Enfin, vous pouvez être en droit de soumettre les litiges liés à des matières couvertes par le code européen des communications électroniques à un organisme indépendant de règlement des litiges désigné dans votre pays. Une liste des organismes indépendants compétents pour le règlement des litiges dans les pays de l&#39;UE et des liens vers leurs sites Web est disponible sur le site Web de règlement des litiges en ligne de l&#39;Union européenne : Règlement des litiges en ligne - Commission européenne (<a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>).</p>
            <h2 id="8-vous-ne-pouvez-pas-acc-der-aux-services-d-urgence-via-nos-services-de-messagerie">8. Vous ne pouvez pas accéder aux services d&#39;urgence via nos services de messagerie</h2>
            <p>Il existe des différences importantes entre nos services et votre téléphone mobile et un téléphone fixe et des services SMS. Nos services ne permettent pas d&#39;accéder aux services d&#39;urgence ou aux fournisseurs de services d&#39;urgence, y compris la police, les pompiers ou les hôpitaux, ou de se connecter autrement aux points de réponse de la sécurité publique. Vous devez vous assurer que vous pouvez contacter vos prestataires de services d&#39;urgence concernés via un téléphone mobile, un téléphone fixe ou un autre service.</p>
        </>
    )
}