import React, { useRef } from "react"
import {useTranslation} from "react-i18next";
import style from "./MotionParallaxPhone.module.scss"
import { motion, useScroll, useSpring, useTransform } from "framer-motion"
// Images
import frontPhoneEN from "../../../assets/images/app_screens_mockups/mockup_download_section_en.png";
import frontPhoneFR from "../../../assets/images/app_screens_mockups/mockup_download_section_fr.png";

export default function MotionParallaxPhone() {
    const { i18n} = useTranslation();

    const ref = useRef(null)
    const { scrollYProgress } = useScroll({ target: ref })
    const yRange2 = useTransform(scrollYProgress, [0, 0.7, 1], [-75, 50, 100])
    const yRange3 = useTransform(scrollYProgress, [0, 0.7, 1], [-75, 50, 100])

    const y2 = useSpring(yRange2, { stiffness: 100, damping: 30,restDelta: 0.1, velocity: -20 })
    const y3 = useSpring(yRange3, { stiffness: 50, damping: 40,restDelta: 1.1, velocity: 5 })

    const renderPhone = () => {
        if (i18n.language === 'fr') {
            return <img className={style.frontphone} src={frontPhoneFR} alt="Phone demo FR" />
        }

        return <img className={style.frontphone} src={frontPhoneEN} alt="Phone demo EN" />
    }

    return (
        <div className={style.container} ref={ref}>
            <motion.div className={style.background} style={{ y: y3 }}></motion.div>
            <motion.div style={{ y: y2, zIndex: 1000 }}>
                { renderPhone() }
            </motion.div>
        </div>
    )
}
