import team1 from "../../../../../assets/images/team/michael_cadre.png"
import team1n from "../../../../../assets/images/team/michael.jpeg"
import team2 from "../../../../../assets/images/team/xavier_cadre.png"
import team2n from "../../../../../assets/images/team/xavier.jpeg"
import team4 from "../../../../../assets/images/team/michela_cadre.png"
import team4n from "../../../../../assets/images/team/michela.jpeg"
import team5 from "../../../../../assets/images/team/amir_cadre.jpg"
import team5n from "../../../../../assets/images/team/amir.jpg"
import team6 from "../../../../../assets/images/team/vince_cadre.jpg"
import team6n from "../../../../../assets/images/team/vince.jpg"
import team7 from "../../../../../assets/images/team/julien_cadre.jpg"
import team7n from "../../../../../assets/images/team/julien.jpg"
import team8 from "../../../../../assets/images/team/emilie_cadre.jpg"
import team8n from "../../../../../assets/images/team/emilie.jpg"

export const teamates = [
    {
        id: 1,
        image: team1,
        imageNoBorder: team1n,
        name: "team.team_1.name",
        title: "team.team_1.job",
        description: "team.team_1.description1",
        socialNetworks: {
            instagram: { url: "https://instagram.com" },
            facebook: { url: "https://facebook.com" },
        },
    },
    {
        id: 2,
        image: team2,
        imageNoBorder: team2n,
        name: "team.team_2.name",
        title: "team.team_2.job",
        description: "team.team_2.description1",
        socialNetworks: {
            instagram: { url: "https://instagram.com" },
            facebook: { url: "https://facebook.com" },
        },
    },
    {
        id: 3,
        image: team4,
        imageNoBorder: team4n,
        name: "team.team_4.name",
        title: "team.team_4.job",
        description: "team.team_4.description1",
        socialNetworks: {
            instagram: { url: "https://instagram.com" },
            facebook: { url: "https://facebook.com" },
        },
    },
    {
        id: 4,
        image: team5,
        imageNoBorder: team5n,
        name: "team.team_5.name",
        title: "team.team_5.job",
        description: "team.team_5.description1",
        socialNetworks: {
            instagram: { url: "https://instagram.com" },
            facebook: { url: "https://facebook.com" },
        },
    },
    {
        id: 5,
        image: team6,
        imageNoBorder: team6n,
        name: "team.team_6.name",
        title: "team.team_6.job",
        description: "team.team_6.description1",
        socialNetworks: {
            instagram: { url: "https://instagram.com" },
            facebook: { url: "https://facebook.com" },
        },
    },
    {
        id: 6,
        image: team7,
        imageNoBorder: team7n,
        name: "team.team_7.name",
        title: "team.team_7.job",
        description: "team.team_7.description1",
        socialNetworks: {
            instagram: { url: "https://instagram.com" },
            facebook: { url: "https://facebook.com" },
        },
    },
    {
        id: 4,
        image: team8,
        imageNoBorder: team8n,
        name: "team.team_8.name",
        title: "team.team_8.job",
        description: "team.team_8.description1",
        socialNetworks: {
            instagram: { url: "https://instagram.com" },
            facebook: { url: "https://facebook.com" },
        },
    },
]
