import React from "react"
import {useTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import style from "../footer.module.scss";
import {HashLink} from "react-router-hash-link";

export default function FooterMenuLegal() {
    const {t} = useTranslation();
    return (
        <Container>
            <Row>
                <Col className={style.footertitle}>{[t("footer.menu.legal.title")]}</Col>
            </Row>
            <Row className={style.paddinglink}>
                <Col className={style.footertext}>
                    <HashLink
                        to="/cgu"
                        spy="true"
                        smooth={true}
                        offset={-150}
                        duration={2000}
                    >
                        {t("footer.menu.legal.cgu")}
                    </HashLink>
                </Col>
            </Row>
            <Row className={style.paddinglink}>
                <Col className={style.footertext}>
                    <HashLink
                        to="/politic"
                        spy="true"
                        smooth={true}
                        offset={-150}
                        duration={2000}
                    >
                        {t("footer.menu.legal.politic")}
                    </HashLink>
                </Col>
            </Row>
        </Container>
    )
}