import React from "react"
// Components
import FMScreen from "../components/animation/FMScreen/FMScreen"
import {Col, Container, Row} from "react-bootstrap";
import CguTitle from "../components/page-sections/cgu/cgu-title/CguTitle";
import {useTranslation} from "react-i18next";
import CguFR from "../components/page-sections/cgu/bylang/CguFR";
import CguEN from "../components/page-sections/cgu/bylang/CguEN";

export default function CguPage() {

    const {i18n} = useTranslation();

    return (
        <div className="section-border-end pb-5 mb-5">
            <Container>
                <Row>
                    <Col><CguTitle/></Col>
                </Row>
                <Row>
                    <Col>
                        { i18n.language === "fr" && <CguFR /> }
                        { i18n.language === "en" && <CguEN /> }
                    </Col>
                </Row>
            </Container>

            <FMScreen/>
        </div>
    )
}
