import React from "react"
import { useTranslation } from "react-i18next"
import MotionParallaxText from "../../../animation/MotionParallaxText/MotionParallaxText"
import style from "./section-humans.module.scss"
import CardCustom from "../../../card/CardCustom"
import { Container, Col, Row } from "react-bootstrap"

export default function SectionHumans() {
    const { t } = useTranslation()

    function getBadgeColorClass(index) {
        switch (index % 3) {
            case 0:
                return style.badgeYellow // Assurez-vous d'avoir cette classe dans votre SCSS
            case 1:
                return style.badgeBlack // Assurez-vous d'avoir cette classe dans votre SCSS
            case 2:
                return style.badgeGrey // Assurez-vous d'avoir cette classe dans votre SCSS
            default:
                return ""
        }
    }

    const isMobile = window.innerWidth <= 768
    return (
        <div className={style.sectionhumans}>
            <Container fluid>
                <Row className={style.titleBloc}>
                    <Col lg={{ offset: 1 }} xs={{ offset: 0, span: 12 }}>
                        <div className={style.title + " d-flex align-items-center"}>
                            <CardCustom
                                text={[]}
                                title={[t("profile.title_1"), t("profile.title_2"), t("profile.title_3")]}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className={style.paralaxBloc}>
                    <div className={" d-flex align-items-center"}>
                        <div className={style.paralaxtext}>
                            <Row>
                                <MotionParallaxText baseVelocity={-10}>
                                    {t("profile.row_1", { returnObjects: true }).map((text, index) => (
                                        <span className={`${style.badge} ${getBadgeColorClass(index)}`} key={index}>
                                            {text}
                                        </span>
                                    ))}
                                </MotionParallaxText>
                            </Row>
                            <Row className="pt-3">
                                <MotionParallaxText baseVelocity={10}>
                                    {t("profile.row_2", { returnObjects: true }).map((text, index) => (
                                        <span className={`${style.badge} ${getBadgeColorClass(index)}`} key={index}>
                                            {text}
                                        </span>
                                    ))}
                                </MotionParallaxText>
                            </Row>
                            <Row className="pt-3">
                                <MotionParallaxText baseVelocity={-10}>
                                    {t("profile.row_3", { returnObjects: true }).map((text, index) => (
                                        <span className={`${style.badge} ${getBadgeColorClass(index)}`} key={index}>
                                            {text}
                                        </span>
                                    ))}
                                </MotionParallaxText>
                            </Row>
                            {isMobile && (
                                <>
                                    <Row className="pt-3">
                                        <MotionParallaxText baseVelocity={10}>
                                            {t("profile.row_1", { returnObjects: true }).map((text, index) => (
                                                <span
                                                    className={`${style.badge} ${getBadgeColorClass(index)}`}
                                                    key={index}
                                                >
                                                    {text}
                                                </span>
                                            ))}
                                        </MotionParallaxText>
                                    </Row>
                                    <Row className="pt-3">
                                        <MotionParallaxText baseVelocity={-10}>
                                            {t("profile.row_2", { returnObjects: true }).map((text, index) => (
                                                <span
                                                    className={`${style.badge} ${getBadgeColorClass(index)}`}
                                                    key={index}
                                                >
                                                    {text}
                                                </span>
                                            ))}
                                        </MotionParallaxText>
                                    </Row>
                                    <Row className="pt-3">
                                        <MotionParallaxText baseVelocity={10}>
                                            {t("profile.row_3", { returnObjects: true }).map((text, index) => (
                                                <span
                                                    className={`${style.badge} ${getBadgeColorClass(index)}`}
                                                    key={index}
                                                >
                                                    {text}
                                                </span>
                                            ))}
                                        </MotionParallaxText>
                                    </Row>
                                </>
                            )}
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    )
}
