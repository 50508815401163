import React, { useState } from "react"
import style from "./header.module.scss"
import logo from "../../assets/images/logos/logo_keym_full.svg"
import MenuButton from "../menu/menuButton/MenuButton"
import Menu from "../menu/menu/Menu"
import BurgerMenu from "../menu/burgerMenu/BurgerMenu"
import Languages from "../languages/Languages"
import useSection from "../../lib/hooks/useSection"
import { useScroll } from "framer-motion"
import DownloadButton from "../download-button/DownloadButton"
import { Container, Row, Col } from "react-bootstrap"
import { useLocation } from "react-router-dom"
import { HashLink } from "react-router-hash-link"

export default function Header() {
    const [openMenu, setOpenMenu] = useState(false)
    const [selectedParent, setSelectedParent] = useState(null)
    const { onChangeActiveLink } = useSection()
    const { scrollYProgress } = useScroll()
    const location = useLocation()

    const [isTransparent, setIsTransparent] = useState([true])

    const isMobile = window.innerWidth <= 768

    const onChangeMenu = () => {
        setSelectedParent(null)
        setOpenMenu(!openMenu)
    }

    scrollYProgress.onChange((x) => {
        setIsTransparent(x === 0)
    })

    const clickLogo = () => {
        onChangeActiveLink(null)
        if (location.pathname !== "/") {
            window.location.href = "/"
        }
    }

    return (
        <div className={style.container} style={{ backgroundColor: isTransparent ? "transparent" : "#101010" }}>
            <Container fluid>
                <Row>
                    <Col xs={{ span: 8 }} lg={{ offset: 1, span: 1 }}>
                        <HashLink to="/#" spy="true" smooth={true} onClick={clickLogo} style={{ cursor: "pointer" }}>
                            <img src={logo} alt="keym logo" height={isMobile ? 60 : 70} className={style.logo} />
                        </HashLink>
                    </Col>

                    <Col xs lg={{ span: 8 }}>
                        <div className={style.menu}>
                            <Row>
                                <Col sm={12} lg={8}>
                                    <BurgerMenu
                                        isOpen={openMenu}
                                        onChangeIsOpen={() => setOpenMenu(!openMenu)}
                                        parent={selectedParent}
                                        onChangeParent={(parent) => setSelectedParent(parent)}
                                    />
                                    <Menu />
                                </Col>
                                <Col sm={12} lg={3} className="d-none d-sm-block">
                                    <DownloadButton />
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col xs>
                        <MenuButton isOpen={openMenu} onChangeIsOpen={() => onChangeMenu()} />

                        <div className={style.languages}>
                            <Languages />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
