import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import FooterJoin from "./join/FooterJoin"
import FooterMenuProduct from "./menu-product/FooterMenuProduct"
import FooterMenuLegal from "./menu-legal/FooterMenuLegal"
import FooterCatch from "./catch/FooterCatch"
import style from "./footer.module.scss"
import { useTranslation } from "react-i18next"
import Notice from "./notice/Notice"

export default function Footer() {
    const { t } = useTranslation()
    return (
        <Container fluid>
            <Row className={style.footer + " pt-5 pb-1 ps-3 pe-3 ps-md-0 pe-md-0"}>
                <Col lg={{ offset: 1, span: 10 }}>
                    <Row>
                        <Col lg="4" sm={12} xs={12}>
                            <FooterJoin />
                        </Col>
                        <Col lg={{ span: 2, offset: 1 }} xs={{ span: 6, offset: 0 }} className={"pt-5 pt-sm-0"}>
                            <FooterMenuProduct />
                        </Col>
                        <Col lg="3" xs={{ span: 6, offset: 0 }} className={"pt-5 pt-sm-0"}>
                            <FooterMenuLegal />
                        </Col>
                        <Col lg="2" xs={{ span: 12, offset: 0 }} className={"pt-5 pt-sm-0"}>
                            <FooterCatch />
                        </Col>
                    </Row>

                    <Row className="d-block d-sm-none d-md-none d-lg-none">
                        <Col className={"pt-5"}>
                            <Notice />
                        </Col>
                    </Row>

                    <Row>
                        <Col className={"pt-5 d-none d-sm-block d-md-block d-lg-block "}>
                            <Notice />
                        </Col>
                    </Row>

                    <Row className={"pt-5 " + style.copyright}>
                        <Col>
                            {t("footer-right-brand")} | {t("footer-right-rights")}
                            <span className="fw-bold">{t("footer-right-mentions")}</span> ·
                            <span className="fw-bold">{t("footer-right-perso")}</span> ·
                            <span className="fw-bold">{t("footer-right-cookies")}</span>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
