import React, {useState} from "react"
// Components
import FMScreen from "../components/animation/FMScreen/FMScreen";
import FaqTitle from "../components/page-sections/faq/faq-title/FaqTitle";
import {Container, Row, Col} from "react-bootstrap";
import FaqCategories from "../components/page-sections/faq/faq-categories/FaqCategories";
import FaqQuestions from "../components/page-sections/faq/faq-questions/FaqQuestions";
// Images
// Translation

export default function FaqPage() {

    const [itemSelected, setItemSelected] = useState("generals");

    const changeCategory = category => {
        setItemSelected(category);
    }

    return (
        <div className="section-border-end pt-5" style={{paddingBottom:"15rem"}}>
            <Container>
                <Row className="pb-5 pt-5">
                    <Col><FaqTitle/></Col>
                </Row>
                <Row className="pb-5">
                    <Col><FaqCategories onSelectCategory={changeCategory}/></Col>
                </Row>
                {itemSelected &&
                    <Row className="pb-5 pt-5">
                        <FaqQuestions category={itemSelected}/>
                    </Row>
                }
            </Container>
            <FMScreen/>
        </div>
    )
}
