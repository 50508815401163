import React, { useState } from "react"
import iconSeparator from "../../../../assets/images/separators/icon_bullet_1.svg"
import Image from "react-bootstrap/Image"
import style from "./faq-categories.module.scss"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

FaqCategories.propTypes = {
    onSelectCategory: PropTypes.func.isRequired,
}

export default function FaqCategories({ onSelectCategory }) {
    const { t } = useTranslation()
    const [itemSelected, setItemSelected] = useState("generals")

    // Pour des raisons de temps j'ai mis les clefs en dur ici
    // @todo utiliser les clefs de FaqQuestionsByCategory en s'assurant de l'ordre
    const categories = ["generals", "inscription", "profils", "confidential", "contact"]

    const getCategoryStyle = (category) => {
        let str = "float-start " + style.categorylabel
        if (category === itemSelected) {
            str += " colorActive"
        }
        return str
    }

    const changeCategory = (category) => {
        setItemSelected(category)
        onSelectCategory(category)
    }

    return (
        <div>
            {categories.map((item, index) => (
                <div key={"cat-" + index}>
                    <div
                        role="button"
                        className={getCategoryStyle(item) + " pb-3"}
                        onClick={() => changeCategory(item)}
                    >
                        {t("faq.category." + item)}
                    </div>
                    {index !== categories.length - 1 && (
                        <span>
                            <Image
                                src={iconSeparator}
                                height={26}
                                alt=" * "
                                className={"float-start " + style.categorySeparator}
                            />
                        </span>
                    )}
                </div>
            ))}
        </div>
    )
}
