import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import arrow from "../../../../../assets/images/arrows/arrow.svg"
import style from "./Carousel.module.scss"
import { AnimatePresence, motion } from "framer-motion"
import { teamates } from "./teams"
import { useTranslation } from "react-i18next"
import Profile from "../profile/Profile"
import { Col, Row } from "react-bootstrap"

Carousel.propTypes = {
    selectedCard: PropTypes.number,
    onChangeSelectedCard: PropTypes.func,
}

Carousel.defaultProps = {
    selectedCard: null,
    onChangeSelectedCard: () => {},
}

export default function Carousel({ selectedCard, onChangeSelectedCard }) {
    const { t } = useTranslation()
    const containerRef = useRef()
    const [canDrag, setCanDrag] = useState(false)
    const [position, setPosition] = useState(0)
    const [currentCard, setCurrentCard] = useState(null)
    const [isDragging, setIsDragging] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    const totalImages = Math.ceil(teamates.length / 3)

    useEffect(() => {
        !selectedCard ? setCanDrag(true) : setCanDrag(false)
        selectedCard && setCurrentCard(selectedCard)
    }, [selectedCard])

    const selectCard = (card) => {
        if (isDragging) {
            return
        }
        if (!selectedCard) {
            onChangeSelectedCard(card)
            return
        }
        onChangeSelectedCard(null)
    }

    const eventControl = (event) => {
        if (event.type === "pointermove" || event.type === "mousemove" || event.type === "touchmove") {
            setIsDragging(true)
            return
        }

        if (event.type === "pointerup" || event.type === "mouseup" || event.type === "touchend") {
            setTimeout(() => {
                setIsDragging(false)
            }, 100)
        }
    }

    const onDragCards = (event) => {
        eventControl(event)
        setTimeout(() => {
            setPosition(containerRef?.current?.getBoundingClientRect().left)
        }, 100)
    }

    const onChangeCurrentCard = () => {
        if (currentCard === teamates.length) {
            setCurrentCard(1)
        } else {
            setCurrentCard((prev) => prev + 1)
        }
    }

    const onHide = () => {
        if (currentCard) {
            setCurrentCard(null)
            onChangeSelectedCard(null)
        }
    }

    const handleChangeCurrent = (e) => {
        e.stopPropagation()
        onChangeCurrentCard(currentCard + 1)
    }

    const handleChangeSliderPrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1)
        }
    }

    const handleChangeSliderNext = () => {
        if (currentIndex < totalImages - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1)
        }
    }

    console.log(totalImages)

    const handleChangeCurrentPrev = (e) => {
        e.stopPropagation()
        onChangeCurrentCard(currentCard - 1)
    }

    const cardVariants = {
        selected: {
            opacity: 1,
            transition: { duration: 0.35 },
        },
        notSelected: {
            opacity: selectedCard ? 0.5 : 1,
            transition: { duration: 0.35 },
        },
    }

    window.addEventListener("click", function (e) {
        e.stopPropagation()
        if (!document.getElementById("caroussel-team").contains(e.target)) {
            onHide()
        }
    })

    window.addEventListener("keydown", function (e) {
        e.stopPropagation()
        if (e.key === "Escape") {
            onHide()
        }
    })

    return (
        <div id="caroussel-team">
            <div className={style.lib} onClick={() => onHide()} />
            <div className={style.slider} onClick={() => onHide()}>
                <Row>
                    <Col lg={{ offset: 1, span: 10 }}>
                        <div
                            className={style.arrow_div + " " + (currentCard !== null ? style.innerSliderSelected : "")}
                        >
                            <span>
                                <motion.span layout onClick={() => handleChangeSliderPrev()} role="button">
                                    <motion.img className={style.prevBtn} src={arrow} layout alt="" />
                                </motion.span>
                            </span>

                            <span>
                                <motion.span layout onClick={() => handleChangeSliderNext()} role="button">
                                    <motion.img className={style.nextBtn} src={arrow} layout alt="" />
                                </motion.span>
                            </span>
                        </div>
                        <motion.div
                            layout
                            className={
                                style.innerSlider + " " + (currentCard !== null ? style.innerSliderSelected : "")
                            }
                            initial={{ x: 0 }}
                            animate={{
                                x: -currentIndex * (containerRef.current ? containerRef.current.offsetWidth : 0),
                                transition: {
                                    type: "spring",
                                    damping: 20,
                                    stiffness: 100,
                                },
                            }}
                            ref={containerRef}
                            drag={canDrag ? "x" : false}
                            dragConstraints={{
                                left: -(containerRef?.current?.scrollWidth - window.innerWidth + 200),
                                right: 0,
                            }}
                            dragElastic={0}
                            dragMomentum={false}
                            onDrag={(event) => onDragCards(event)}
                            onDragEnd={(event) => eventControl(event)}
                        >
                            {teamates.map((card, index) => (
                                <motion.div
                                    className={selectedCard === card.id ? style.openedCard : style.card}
                                    layout
                                    key={index}
                                    variants={cardVariants}
                                    animate={selectedCard === card.id ? "selected" : "notSelected"}
                                    style={{
                                        left: selectedCard === card.id && window.innerWidth * 0.1 + Math.abs(position),
                                    }}
                                    onClick={() => selectCard(card.id)}
                                >
                                    <motion.img
                                        src={card.image}
                                        alt={`Photo de ${t(card.name)}`}
                                        layout
                                        className={style.photo}
                                        style={{ marginTop: selectedCard === card.id && "3rem" }}
                                    />
                                </motion.div>
                            ))}
                        </motion.div>
                    </Col>
                </Row>
                {currentCard && (
                    <Row>
                        <Col lg={{ offset: 1, span: 10 }}>
                            <AnimatePresence>
                                <Profile
                                    card={teamates[currentCard - 1]}
                                    handleChangeCurrent={handleChangeCurrent}
                                    handleChangeCurrentPrev={handleChangeCurrentPrev}
                                />
                            </AnimatePresence>
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    )
}
