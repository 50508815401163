import React from "react"

export default function ContractFR() {

    return (
        <>
            <p>Fournisseur de services :<br/>
                Golly Sàrl<br/>
                17, route d&#39;Esch<br/>
                L-1470 Luxembourg,</p>
            <p>Vous pouvez également nous contacter via notre site Web ou par e-mail hello@keym-music.com.</p>
            <p>Ce récapitulatif du contrat fournit les principaux éléments de l&#39;offre de KEYM Service conformément à la législation de l&#39;UE.</p>
            <p><strong>Prestations de service</strong>
                Nous vous fournissons des moyens de communiquer avec d&#39;autres utilisateurs de KEYM par le biais de messages, d&#39;appels vocaux, en envoyant des images et des vidéos et en partageant votre position avec d&#39;autres lorsque vous le souhaitez.</p>
            <p><strong>Prix</strong>
                Nous ne facturons pas l&#39;utilisation de nos services, sauf si nous en convenons autrement avec vous. Tous les utilisateurs de notre messagerie sont responsables des forfaits de données du réseau mobile, des frais d&#39;accès à Internet et des autres frais et taxes associés à l&#39;utilisation de nos services.</p>
            <p><strong>Durée, renouvellement et résiliation</strong>
                Vous pouvez cesser d&#39;utiliser nos services à tout moment pour quelque raison que ce soit en supprimant votre compte. Nous pouvons modifier, suspendre ou résilier votre accès ou votre utilisation de nos services pour les raisons décrites dans les conditions d&#39;utilisation de KEYM.</p>
            <p><strong>Autres informations pertinentes</strong>
                Aucun</p>
            <p>Dernière modification : <em>1er mai 2023</em></p>

        </>
    )
}