/* eslint-disable react/forbid-prop-types */
import React from "react"
import PropTypes from "prop-types"
import style from "./FMTitle.module.scss"
import { motion } from "framer-motion"

FMTitle.propTypes = {
    title: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    variants: PropTypes.object.isRequired,
}

export default function FMTitle({ title, index, variants }) {
    return (
        <div className={style.container}>
            <motion.h2 variants={variants} custom={index}>
                {title}
            </motion.h2>
        </div>
    )
}
