import i18n from "i18next"
import {initReactI18next} from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import {language} from "./language"
import translationEN from "./translation/en.json"
import translationFR from "./translation/fr.json"

const defaultLanguage = language.EN
const resources = {
    en: {
        translation: translationEN,
    },
    fr: {
        translation: translationFR,
    },
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['path', 'cookie', 'localStorage'],
        },
        resources,
        fallbackLng: defaultLanguage,
        supportedLngs: [language.EN, language.FR],
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
