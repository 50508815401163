import React from "react"
import {useTranslation} from "react-i18next";
import style from "./Notice.module.scss";

export default function Notice() {
    const {t} = useTranslation();
    return (
        <span className={style.notice}>
            <span className="colorActive fw-normal">*</span>
            {t("footer.join.notice.start")} <a href="#" className={"colorActive fw-bold"}>{t("footer.join.notice.link")}</a> {t("footer.join.notice.end")}
        </span>
    )
}