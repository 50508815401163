import React from "react"
import PropTypes from "prop-types"
import style from "./burgerMenu.module.scss"
import { AnimatePresence, motion } from "framer-motion"
import { MENU } from "../../../lib/menu"
import classnames from "classnames"
import { medias } from "../../../lib/medias"
import Languages from "../../languages/Languages"
import useSection from "../../../lib/hooks/useSection"
import { useTranslation } from "react-i18next"
import { HashLink } from "react-router-hash-link"

BurgerMenu.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onChangeIsOpen: PropTypes.func,
    onChangeParent: PropTypes.func,
    parent: PropTypes.number,
}

BurgerMenu.defaultProps = {
    onChangeIsOpen: () => {},
    onChangeParent: () => {},
    parent: null,
}

export default function BurgerMenu({ isOpen, onChangeIsOpen, onChangeParent, parent }) {
    const { t } = useTranslation()
    const { activeLink, onChangeActiveLink } = useSection()

    function onSelectedParent(id) {
        if (parent === id) {
            onChangeParent(null)
        } else {
            onChangeParent(id)
        }
    }

    function handleChangeSection(section) {
        onChangeIsOpen(false)
        onChangeActiveLink(section)
    }

    return (
        <AnimatePresence initial={false}>
            <motion.div className={style.wrapper} variants={wrapperVariants} animate={isOpen ? "visible" : "hidden"}>
                <motion.div layout className={style.container} variants={containerVariants}>
                    {MENU.map((item) => {
                        {
                            if (!item?.children)
                                return (
                                    <div key={item.id}>
                                        <motion.div
                                            className={style.parent}
                                            onClick={onChangeIsOpen}
                                            variants={parentVariants}
                                        >
                                            <HashLink
                                                to={item.to}
                                                scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
                                                spy="true"
                                                smooth={true}
                                                offset={-200}
                                                duration={2000}
                                                onClick={() => handleChangeSection(item.name)}
                                            >
                                                <span className={activeLink === item.name ? style.activeLink : ""}>
                                                    {t(item.name).toUpperCase()}
                                                </span>
                                            </HashLink>
                                        </motion.div>
                                    </div>
                                )
                        }

                        return (
                            <div key={item.id}>
                                <motion.div
                                    layout
                                    className={classnames(style.parent, {
                                        [style.parent_active]:
                                            item?.children && item.children.find((c) => c.name === activeLink),
                                    })}
                                    variants={parentVariants}
                                    onClick={() => onSelectedParent(item.id)}
                                >
                                    <div>{t(item.name)}</div>
                                    <AnimatePresence mode="popLayout">
                                        {parent === item.id && (
                                            <motion.div
                                                layout
                                                className={style.childrenWrapper}
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1, transition: { delay: 0.5 } }}
                                            >
                                                <motion.div
                                                    layout
                                                    className={style.hover}
                                                    initial={{ x: 0 }}
                                                    animate={{ x: 500, transition: { delay: 0.5, duration: 0.8 } }}
                                                ></motion.div>
                                                <motion.div
                                                    layout
                                                    className={style.childrenContainer}
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1, transition: { delay: 0.7 } }}
                                                >
                                                    {item.children.map(({ name, to }, i) => (
                                                        <motion.div
                                                            key={i}
                                                            onClick={onChangeIsOpen}
                                                            layout
                                                            className={style.children}
                                                        >
                                                            <HashLink
                                                                to={to}
                                                                spy="true"
                                                                smooth={true}
                                                                offset={-200}
                                                                duration={2000}
                                                                onClick={() => handleChangeSection(name)}
                                                            >
                                                                <span
                                                                    className={
                                                                        activeLink === name ? style.activeLink : ""
                                                                    }
                                                                >
                                                                    {t(name).toUpperCase()}
                                                                </span>
                                                            </HashLink>
                                                        </motion.div>
                                                    ))}
                                                </motion.div>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </motion.div>
                            </div>
                        )
                    })}
                    {/* <div>
                        <motion.div className={style.parent} onClick={onChangeIsOpen} variants={parentVariants}>
                            <HashLink
                                to="/"
                                scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
                                spy="true"
                                smooth={true}
                                offset={-200}
                                duration={2000}
                                onClick={() => handleChangeSection("menu.product.title")}
                            >
                                <span className={activeLink === "menu.product.title" ? style.activeLink : ""}>
                                    BONJOUR
                                </span>
                            </HashLink>
                        </motion.div>
                    </div> */}
                </motion.div>

                <div className="overflow-hidden">
                    <motion.div className={style.media} variants={mediaVariants}>
                        {medias.map((media) => (
                            <div key={media.id} onClick={() => window.open(media.link, "_blank")}>
                                <img src={media.image} alt={`logo ${media.id}`} />
                            </div>
                        ))}
                    </motion.div>
                </div>

                <div className="overflow-hidden">
                    <motion.div className={style.languages} variants={languagesVariants}>
                        <Languages />
                    </motion.div>
                </div>
            </motion.div>
        </AnimatePresence>
    )
}

const wrapperVariants = {
    hidden: {
        opacity: 0,
        height: 0,
        transition: {
            when: "afterChildren",
        },
    },
    visible: {
        opacity: 1,
        height: "100vh",
        when: "beforeChildren",
    },
}

const containerVariants = {
    hidden: {
        y: -350,
        transition: {
            type: "linear",
            when: "afterChildren",
            staggerChildren: 0.1,
            staggerDirection: -1,
        },
    },
    visible: {
        y: 0,
        transition: {
            type: "linear",
            when: "beforeChildren",
            staggerChildren: 0.1,
            staggerDirection: 1,
        },
    },
}

const parentVariants = {
    hidden: {
        opacity: 0,
        y: -350,
        transition: {
            type: "linear",
            duration: 0.5,
        },
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            type: "linear",
            duration: 0.5,
        },
    },
}

const mediaVariants = {
    hidden: {
        opacity: 0,
        y: 500,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            type: "linear",
            delay: 1,
            duration: 0.5,
        },
    },
}

const languagesVariants = {
    hidden: {
        opacity: 0,
        y: 500,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            type: "linear",
            delay: 1.1,
            duration: 0.5,
        },
    },
}
