import React from "react"
import { useTranslation } from "react-i18next"
import { Col, Row } from "react-bootstrap"
import style from "../footer.module.scss"
import SocialIcons from "../../social/SocialIcons"

export default function FooterCatch() {
    const { t } = useTranslation()

    const socialNetworks = {
        instagram: { url: "https://www.instagram.com/keym.music" },
        facebook: { url: "https://www.facebook.com/keymxmusic" },
        linkedIN: { url: "https://www.linkedin.com/company/keym-music" },
        mail: { url: "mailto:hello@keym-music.com" },
    }

    return (
        <div>
            <Row>
                <Col>
                    <div className={style.footertitle}>{[t("footer.menu.catch.title")]}</div>
                </Col>
            </Row>

            <SocialIcons socialNetworks={socialNetworks} />
        </div>
    )
}
