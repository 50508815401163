import React, { useRef } from "react"
import PropTypes from "prop-types"
import style from "./MotionParallaxText.module.scss"
import {
    motion,
    useScroll,
    useSpring,
    useTransform,
    useMotionValue,
    useVelocity,
    useAnimationFrame,
} from "framer-motion"
import { wrap } from "@motionone/utils"

MotionParallaxText.propTypes = {
    children: PropTypes.node,
    baseVelocity: PropTypes.number,
}

MotionParallaxText.defaultProps = {
    children: null,
    baseVelocity: 0,
}

export default function MotionParallaxText({ children, baseVelocity }) {
    const baseX = useMotionValue(0)
    const { scrollY } = useScroll()
    const scrollVelocity = useVelocity(scrollY)
    const smoothVelocity = useSpring(scrollVelocity, {
        damping: 50,
        stiffness: 400,
    })
    const velocityFactor = useTransform(smoothVelocity, [0, 100], [0, 0.2], {
        clamp: false,
    })
    const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`)

    const directionFactor = useRef(null)
    useAnimationFrame((t, delta) => {
        let moveBy = directionFactor.current * baseVelocity * (delta / 10000)

        /**
         * This is what changes the direction of the scroll once we
         * switch scrolling directions.
         */
        if (velocityFactor.get() < 0) {
            directionFactor.current = -1
        } else if (velocityFactor.get() > 0) {
            directionFactor.current = 1
        }

        moveBy += directionFactor.current * moveBy * velocityFactor.get()

        baseX.set(baseX.get() + moveBy)
    })

    return (
        <div className={style.parallax}>
            <motion.div className={style.scroller} style={{ x }}>
                {children}
                {children}
                {children}
                {children}
            </motion.div>
        </div>
    )
}
