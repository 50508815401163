import React from "react"

export default function ContractEN() {

    return (
        <>
            <p>Service provider:
                Gonly Sàrl
                17, route d’Esch
                L - 1470 Luxembourg,</p>
            <p>You may also contact us through our website or by e-mail hello@keym-music.com</p>
            <p>This contract summary provides the main elements of the KEYM Service offer as required by EU law.</p>
            <p><strong>Services</strong>
                We provide ways for you to communicate with other users of KEYM through messages, sending images and video, and sharing your location with others when you choose.</p>
            <p><strong>Price</strong>
                We do not charge for using our services, unless we agree otherwise with you. All users of our messaging are responsible for mobile network data plans, Internet access fees, and other fees and taxes associated with use of our services.</p>
            <p><strong>Duration, renewal and termination</strong>
                You can stop using our services at any time for any reason by deleting your account. We may modify, suspend or terminate your access to or use of our services for the reasons described in the KEYM terms of use.</p>
            <p><strong>Other relevant information</strong>
                None</p>
            <p>Last modified: <em>1st May 2023</em></p>
        </>
    )
}