import React from "react"
import style from "./section.module.scss"
import { motion } from "framer-motion"
import { textVariants } from "../../features/variants"
import FMTitle from "../animation/FMTitle/FMTitle"
import PropTypes from "prop-types";

Section.propTypes = {
    title: PropTypes.arrayOf(PropTypes.string).isRequired,
    text: PropTypes.string.isRequired,
    top: PropTypes.string.isRequired,
    bottom: PropTypes.string.isRequired,
    styled: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
}

export default function Section({ title, text, top, bottom, children, styled }) {
  const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 10.5,
      },
    },
  }

  return (
    <motion.section
      className={style.container}
      style={styled}
      variants={container}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
    >
      <div className={style.content}>
        {top && (
          <div style={{marginBottom: "1rem"}}>
            <motion.div variants={textVariants} custom={0}>
              {top}
            </motion.div>
          </div>
        )}

        {title && title.map((t, i) => <FMTitle key={i} title={t} index={i} variants={textVariants} />)}

        {text && (
          <div style={{marginTop: "2rem"}}>
            <motion.div variants={textVariants} custom={title.length}>
              {text}
            </motion.div>
          </div>
        )}
        {bottom && (
          <div>
            <motion.div variants={textVariants} custom={title.length + 1}>
              {bottom}
            </motion.div>
          </div>
        )}
      </div>
      {children}
    </motion.section>
  )
}
