import React from "react"
// Components
import FMScreen from "../components/animation/FMScreen/FMScreen"

// Translation
import SectionPitch from "../components/page-sections/home/pitch/SectionPitch"
import SectionHumans from "../components/page-sections/home/humans/SectionHumans"
import SectionBanner from "../components/page-sections/home/banner/SectionBanner"
import SectionContact from "../components/page-sections/home/contact/SectionContact"
import SectionTeam from "../components/page-sections/home/team/SectionTeam"
// import Rp from "../components/page-sections/home/rp/Rp"
import SectionFeature from "../components/page-sections/home/features/SectionFeature"
import SectionDownload from "../components/page-sections/home/download/SectionDownload"
import ContactForm from "../components/page-sections/home/contact/form/ContactForm"

export default function HomePage() {
    return (
        <>
            <div id="wrapper">
                <SectionBanner />

                <div id="product">
                    <SectionPitch />
                </div>

                <div id="humans">
                    <SectionHumans />
                </div>

                <div id="features">
                    <SectionFeature />
                </div>

                <div className="background_download">
                    <div id="launch">
                        <SectionDownload />
                    </div>

                    <div id="team">
                        <SectionTeam />
                    </div>
                </div>

                {/* <div id="support" className="section_rp">
                    <Rp />
                </div> */}

                <div id="contact">
                    <SectionContact />
                </div>

                <div id="contactus">
                    <ContactForm />
                </div>

                <FMScreen />
            </div>
        </>
    )
}
