import React from "react"
import ReactPlayer from "react-player"
import PropTypes from "prop-types";

Video.propTypes = {
    playing: PropTypes.bool
}

Video.defaultProps = {
    playing: false,
}

export default function Video({playing}) {
    const externalSource = "https://keym-bucket.fra1.cdn.digitaloceanspaces.com/website/video-2023.mp4"

    return (
        <div>
            <ReactPlayer controls muted={true} playing={playing} url={externalSource} width="100%" height="100%"/>
        </div>
    )
}
