import React, {useState} from "react"
import {Col, Row} from "react-bootstrap";
import FeaturesList from "./features-list/FeaturesList";
import FeaturePicture from "./feature-picture/FeaturePicture";
import {FEATURES} from "../../../../lib/features";

export default function SectionFeature() {

    const isMobile = window.innerWidth <= 768;

    const [currentFeature, setCurrentFeature] = useState(FEATURES[0]);

    const onSelectFeature = (feature) => {
        setCurrentFeature(feature);
    }

    return (
            <Row>
                { !isMobile &&
                    <Col>
                        <FeaturePicture picture={currentFeature ? currentFeature.picture : ''}/>
                    </Col>
                }
                <Col>
                    <FeaturesList onSelectFeature={onSelectFeature}/>
                </Col>
            </Row>
    )
}