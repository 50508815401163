import React, { useState } from "react"
import style from "./languages.module.scss"
import { useTranslation } from "react-i18next"

const LANGUAGES = ["EN", "FR"]

export default function Languages() {
  const { i18n, ready } = useTranslation(null, { useSuspense: false })
  const [language, setLanguage] = useState(i18n.language.toUpperCase())

  function handleChangeLanguage(lng) {
    if (ready) {
      setLanguage(lng)
      i18n.changeLanguage(lng.toLowerCase())
    }
  }

  return (
    <div className={style.container}>
      {LANGUAGES.map((l, i) => (
        <div key={l}>
          <button className={language !== l ? "disabled" : ""} onClick={() => handleChangeLanguage(l)}>
            {l}
          </button>
          {i !== LANGUAGES.length - 1 && <span>|</span>}
        </div>
      ))}
    </div>
  )
}