import React from "react"
import { profilePropstype } from "../../../../../../propstype/profile.propstype"
import { Row, Col } from "react-bootstrap"
import style from "./profile.module.scss"
import styleCardCustom from "./../../../../../card/card-custom.module.scss"
import { useTranslation } from "react-i18next"
// import SocialIcons from "../../../../../social/SocialIcons"
import { motion } from "framer-motion"
import styleProfile from "../profile.module.scss"
import hide from "../../../../../../assets/images/icons/close_cross.svg"

ProfileBio.propTypes = { profile: profilePropstype.isRequired }

export default function ProfileBio({ profile }) {
    const { t } = useTranslation()

    return (
        <Row>
            <Col>
                <Row>
                    <Col className={style.title}>{t(profile.name)}</Col>
                </Row>
                <Row>
                    <Col className={style.subtitle}>{t(profile.title)}</Col>
                </Row>
                <Row className="pt-5">
                    <Col
                        className={styleCardCustom.text}
                        dangerouslySetInnerHTML={{ __html: t(profile.description) }}
                    ></Col>
                </Row>
                <Row className="pt-5">
                    {/* <Col xs lg={{span:6}}>
                        <SocialIcons socialNetworks={profile.socialNetworks}/>
                    </Col> */}

                    <Col className="d-block d-sm-none">
                        <motion.div layout className={"float-end pe-5"} role="button">
                            <motion.img className={styleProfile.imgAction} src={hide} layout alt="" />
                        </motion.div>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
