import React from "react"
import { Dropdown, NavDropdown } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import style from "./download-button.module.scss"
import { StoreLinks } from "../../features/store-links"

export default function DownloadButton() {
    const { t } = useTranslation()
    const appleLink = StoreLinks.apple
    const googleLink = StoreLinks.google

    return (
        <Dropdown>
            <Dropdown.Toggle size="sm" className={style.menuDownloadBtn}>
                {t("menu.download").toUpperCase()}
                <div className={style.btnDrop} />
            </Dropdown.Toggle>

            <Dropdown.Menu className={style.menuDownloadMenu}>
                <Dropdown.Item href={googleLink} className={"text-center " + style.menuDownloadItem}>
                    <div className={style.btnGooglePlay}>
                        <div className={style.availableon}>{t("menu.download.availableon")}</div>
                    </div>
                </Dropdown.Item>
                <NavDropdown.Divider className={style.menuDownloadItemDivideru} />
                <Dropdown.Item href={appleLink} className={"text-center " + style.menuDownloadItem}>
                    <div className={style.btnAppleStore}>
                        <div className={style.availableon}>{t("menu.download.downloadon")}</div>
                    </div>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}
