import React from "react"
import {useTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import style from "../footer.module.scss";
import {HashLink} from 'react-router-hash-link';
export default function FooterMenuProduct() {
    const {t} = useTranslation();
    return (
        <Container>
            <Row>
                <Col className={style.footertitle}>
                    {t("footer.menu.product.title")}
                </Col>
            </Row>
            <Row className={style.paddinglink}>
                <Col className={style.footertext}>
                    <HashLink
                        to="/#product"
                        spy="true"
                        smooth={true}
                        offset={-150}
                        duration={2000}
                    >
                        {t("footer.menu.product.product")}
                    </HashLink>
                </Col>
            </Row>
            <Row className={style.paddinglink}>
                <Col className={style.footertext}>
                    <HashLink
                        to="/#humans"
                        spy="true"
                        smooth={true}
                        offset={-150}
                        duration={2000}
                    >
                        {t("footer.menu.product.humans")}
                    </HashLink>
                </Col>
            </Row>
            <Row>
                <Col className={style.footertext}>
                    <HashLink
                        to="/faq#root"
                        spy="true"
                        smooth={true}
                        offset={-150}
                        duration={2000}
                    >
                        {t("footer.menu.product.faq")}
                    </HashLink>
                </Col>
            </Row>
        </Container>
    )
}