import React from "react"

export default function CguFR() {

    return (
        <>
            <p className="text-center">KEYM la meilleure façon d&#39;expérimenter les arts musicaux !</p>
            <h2 id="1-objet">1. Objet</h2>
            <p>Les présentes conditions d&#39;utilisation (les « Conditions » ou les « Conditions d&#39;utilisation ») régissent l&#39;utilisation de la plate-forme musicale KEYM, sauf indication expresse que des conditions distinctes s&#39;appliquent, et fournissent des informations sur le service musical KEYM (la &#39;Service&#39;), décrites ci-dessous. Lorsque vous créez un compte KEYM ou utilisez KEYM, vous acceptez ces Conditions.</p>
            <p>Cliquez ici pour un <a href={"contract"}>résumé du contrat</a> et <a href={"eecc"}>ici</a> pour d&#39;autres informations que nous sommes tenus de fournir en vertu du Code européen des communications électroniques.</p>
            <h2 id="2-qui-nous-sommes">2. Qui nous sommes</h2>
            <p>Gonly Sàrl est une société de droit luxembourgeois, ayant son siège social à L-1470 Luxembourg, 17, route d&#39;Esch, immatriculée au Registre du Commerce et des Sociétés de Luxembourg sous le numéro B256133, qui exploite la « Plateforme KEYM » ( « Gentil »).</p>
            <p>Ces conditions d&#39;utilisation constituent donc un accord entre vous et Gonly.</p>
            <h2 id="3-le-service-keym">3. Le Service KEYM</h2>
            <p>Nous acceptons de vous fournir le Service KEYM. Le service comprend tous les produits, fonctionnalités, applications, services, technologies et logiciels KEYM que nous fournissons pour faire avancer l&#39;objectif de KEYM : partager votre passion pour les arts musicaux avec les personnes ayant la même passion ou les mêmes intérêts.</p>
            <p>Le service KEYM a les fonctionnalités suivantes :</p>
            <ul>
                <li>Offrir des opportunités personnalisées pour créer, se connecter, communiquer, découvrir et partager. Les gens sont différents. Nous vous proposons donc différentes fonctionnalités pour vous aider à créer, partager, développer votre présence et communiquer avec les personnes sur et en dehors KEYM. Nous construisons des systèmes qui essaient de comprendre qui et quoi vous et les autres vous souciez des arts musicaux, et utilisons ces informations pour vous aider à créer, trouver, rejoindre et partager des expériences qui comptent pour vous. Une partie de cela consiste à mettre en évidence le contenu, les fonctionnalités, les offres et les comptes qui pourraient vous intéresser, et à vous proposer des moyens de découvrir KEYM, en fonction de ce que vous et d&#39;autres faites sur et en dehors de KEYM.</li>
                <li>Promouvoir un environnement positif, inclusif et sécuritaire.</li>
                <li>Nous nous efforçons de développer et d&#39;utiliser des outils et d&#39;offrir des ressources aux membres de la communauté qui contribuent à rendre leurs expériences positives et inclusives. Nous faciliterons cette expérience en offrant une aide proactive. Nous combattons les abus et les violations de nos conditions et politiques, ainsi que les comportements nuisibles et trompeurs. Nous utilisons toutes les informations dont nous disposons - y compris vos informations - pour nous efforcer de sécuriser notre plateforme. Nous pouvons également partager des informations sur une utilisation abusive ou un contenu préjudiciable avec les autorités locales ou européennes chargées de l&#39;application de la loi.</li>
                <li>Développer et utiliser des technologies qui nous aident à servir constamment notre communauté en pleine croissance.</li>
                <li>Créer et utiliser des technologies de pointe qui nous aident à personnaliser, protéger et améliorer notre Service à grande échelle pour une large communauté mondiale. Des technologies telles que les langages de code, le Growth Hacking et les méthodologies Agiles nous donnent le pouvoir d&#39;appliquer des processus complexes à l&#39;ensemble de notre Service. Les technologies automatisées nous aident également à assurer la fonctionnalité et l&#39;intégrité de notre Service.</li>
                <li>Fournir des expériences cohérentes et transparentes à travers d&#39;autres produits de Gonly ou de sociétés associées.</li>
                <li>Assurer l&#39;accès à notre service.</li>
                <li>Pour exploiter notre Service mondial, nous devons stocker et transférer des données sur nos systèmes dans le monde entier, y compris en dehors de votre pays de résidence. L&#39;utilisation de cette infrastructure globale est nécessaire et essentielle pour fournir notre Service. Cette infrastructure peut être détenue ou exploitée par Gonly ou ses sociétés affiliées et sera située, sauf indication contraire, sur le continent européen.</li>
                <li>Vous connecter avec des groupes, des artistes, des marques, des produits et des services d&#39;une manière qui vous tient à cœur.</li>
                <li>Nous utilisons les données de KEYM, ainsi que de partenaires tiers, pour vous montrer des publicités, des offres et d&#39;autres contenus qui, selon nous, seront significatifs pour vous. Et nous essayons de rendre ce contenu aussi pertinent que toutes vos autres expériences sur KEYM.</li>
                <li>Nous utilisons les informations dont nous disposons pour développer, tester et améliorer notre Service et collaborer avec d&#39;autres sur la recherche pour améliorer notre Service et contribuer au bien-être de notre communauté. Cela comprend l&#39;analyse des données dont nous disposons sur nos utilisateurs et la compréhension de la façon dont les gens utilisent nos Services, par exemple, en menant des enquêtes et en testant et dépannant de nouvelles fonctionnalités.</li>
            </ul>
            <h2 id="4-comment-notre-service-est-financ-">4. Comment notre service est financé</h2>
            <p>Au lieu de payer pour utiliser KEYM, en utilisant le service couvert par les présentes conditions, vous reconnaissez que nous pouvons vous montrer des publicités que les entreprises et les organisations nous paient pour promouvoir sur et en dehors des produits Meta Company ainsi que Google Ads. Nous utilisons vos données personnelles, telles que des informations sur votre activité et vos intérêts, pour vous montrer des publicités plus pertinentes pour vous.</p>
            <p>Vous pouvez voir du contenu de marque sur KEYM publié par des titulaires de compte qui font la promotion de produits ou de services sur la base d&#39;une relation commerciale avec le partenaire commercial mentionné dans leur contenu.</p>
            <h2 id="5-la-politique-de-donn-es">5. La politique de données</h2>
            <p>La fourniture de notre service nécessite la collecte et l&#39;utilisation de vos informations. La politique de données explique comment nous recueillons, utilisons et partageons les informations sur les produits Meta. Il explique également les nombreuses façons dont vous pouvez contrôler vos informations, y compris dans les paramètres de confidentialité et de sécurité KEYM.</p>
            <h2 id="6-vos-engagements">6. Vos engagements</h2>
            <p>En échange de notre engagement à fournir le Service, nous vous demandons de prendre les engagements ci-dessous envers nous.</p>
            <h2 id="7-qui-peut-utiliser-keym">7. Qui peut utiliser KEYM</h2>
            <p>Nous voulons que notre Service soit aussi ouvert et inclusif que possible, mais nous voulons aussi qu&#39;il soit sûr, sécurisé et conforme à la loi. Nous avons donc besoin que vous vous engagez à respecter quelques restrictions afin de faire partie de la communauté KEYM.</p>
            <ul>
                <li>Vous devez avoir au moins 14 ans.</li>
                <li>Vous faites l&#39;objet d&#39;une décision d&#39;une autorité judiciaire ou administrative vous refusant de recevoir tout aspect de notre Service en vertu des lois applicables.</li>
                <li>Vous n&#39;êtes pas inscrit sur la liste de refus des services bancaires (par exemple : liste des interdits bancaires).</li>
                <li>Vous n&#39;étiez pas auparavant désactivé ou banni de KEYM pour violation de la loi, de l&#39;une de nos politiques ou pour avoir partagé du contenu choquant ou abusif.</li>
                <li>Vous n&#39;êtes pas un délinquant sexuel condamné.</li>
                <li>Vous n&#39;avez pas été condamné pour plagiat ou pour violation des droits d&#39;auteur.</li>
            </ul>
            <h2 id="8-principaux-aspects-de-l-utilisation-de-keym">8. Principaux aspects de l&#39;utilisation de KEYM</h2>
            <p>Fournir un service sûr et ouvert à une large communauté exige que nous fassions tous notre part.</p>
            <ul>
                <li>Vous ne pouvez pas vous faire passer pour d&#39;autres personnes ou des artistes ou fournir des informations inexactes.</li>
                <li>Vous n&#39;êtes pas obligé de divulguer votre identité sur KEYM, mais vous devez nous fournir des informations exactes et à jour (y compris les informations d&#39;enregistrement), ce qui peut inclure la fourniture de données personnelles. De plus, vous ne pouvez pas vous faire passer pour quelqu&#39;un ou quelque chose que vous n&#39;êtes pas, et vous ne pouvez pas créer de compte pour quelqu&#39;un d&#39;autre à moins d&#39;avoir son autorisation expresse.</li>
                <li>Vous ne pouvez rien faire d&#39;illégal, trompeur ou frauduleux ou à des fins illégales ou non autorisées.</li>
                <li>Vous ne pouvez pas violer (ou aider ou encourager d&#39;autres personnes à violer) ces Conditions ou nos politiques.</li>
                <li>Si vous publiez du <a href={"branding"}>contenu de marque</a>, vous devez vous conformer à nos politiques relatives au contenu de marque.</li>
                <li>Vous ne pouvez rien faire pour interférer avec ou altérer le fonctionnement prévu du Service.</li>
                <li>Cela comprend l&#39;utilisation abusive de tout canal de signalement, de litige ou d&#39;appel, par exemple en faisant des rapports ou des appels frauduleux ou sans fondement.</li>
                <li>Vous ne pouvez pas tenter de créer des comptes ou d&#39;accéder ou de collecter des informations de manière non autorisée.</li>
                <li>Cela inclut la création de comptes ou la collecte d&#39;informations de manière automatisée sans notre autorisation expresse.</li>
                <li>Vous ne pouvez pas vendre, concéder sous licence ou acheter un compte ou des données obtenues auprès de nous ou de notre Service.</li>
                <li>Cela inclut les tentatives d&#39;achat, de vente ou de transfert de tout aspect de votre compte (y compris votre nom d&#39;utilisateur) ; solliciter, collecter ou utiliser les identifiants de connexion ou les badges d&#39;autres utilisateurs ; ou demander ou collecter des noms d&#39;utilisateur, des mots de passe ou des jetons d&#39;accès KEYM détournés.</li>
                <li>Vous ne pouvez pas publier les informations privées ou confidentielles de quelqu&#39;un d&#39;autre sans autorisation ou faire quoi que ce soit qui viole les droits de quelqu&#39;un d&#39;autre, y compris les droits de propriété intellectuelle (par exemple, violation du droit d&#39;auteur, violation de la marque, produits contrefaits ou piratés).</li>
                <li>Vous pouvez utiliser les œuvres de quelqu&#39;un d&#39;autre dans le cadre d&#39;exceptions ou de limitations au droit d&#39;auteur et aux droits connexes en vertu de la loi applicable. Vous déclarez que vous possédez ou avez obtenu tous les droits nécessaires sur le contenu que vous publiez ou partagez. Veuillez noter que d&#39;autres utilisateurs ou tout organisme gouvernemental ou étatique peuvent déposer une plainte pour violation contre vous auprès de nous et cela peut conduire, après un examen approprié, à la suppression immédiate du message.</li>
                <li>Vous ne pouvez pas modifier, traduire, créer des œuvres dérivées ou désosser nos produits ou leurs composants.</li>
                <li>Vous ne pouvez pas utiliser un nom de domaine ou une URL dans votre nom d&#39;utilisateur sans notre consentement écrit préalable.</li>
            </ul>
            <h2 id="9-autorisations-par-vous-en-tant-qu-utilisateur">9. Autorisations par vous en tant qu&#39;utilisateur</h2>
            <p>Dans le cadre de notre accord, vous nous accordez également les autorisations dont nous avons besoin pour fournir le Service.</p>
            <p>Nous ne revendiquons pas la propriété de votre contenu, mais vous nous accordez une licence pour l&#39;utiliser à des fins publicitaires du Service KEYM.</p>
            <p>Nous ne revendiquons pas la propriété de votre contenu que vous publiez sur ou via le Service et vous êtes libre de partager votre contenu avec quiconque, où que vous soyez. Cependant, nous avons besoin de certaines autorisations légales de votre part (appelées « licence ») pour fournir le Service. Lorsque vous partagez, publiez ou téléchargez du contenu couvert par des droits de propriété intellectuelle (tels que de l&#39;audio, de la musique, des photos ou des vidéos) sur ou en relation avec notre Service, vous nous accordez par la présente un droit non exclusif, libre de droits, transférable, licence mondiale pour héberger, utiliser, distribuer, modifier, exécuter, copier, exécuter publiquement ou afficher, traduire et créer des œuvres dérivées de votre contenu (conformément à vos paramètres de confidentialité et d&#39;application). Cette licence prendra fin lorsque votre contenu sera supprimé de nos systèmes. Vous pouvez supprimer du contenu individuellement ou tous à la fois en supprimant votre compte.</p>
            <p>Vous nous autorisez à afficher votre nom d&#39;utilisateur, votre photo de profil et des informations sur vos actions (comme les likes) ou vos relations (comme ci-dessous) à côté ou en relation avec des comptes, des publicités, des offres et d&#39;autres contenus sponsorisés. Par exemple, nous pouvons montrer que vous avez aimé une publication sponsorisée créée par une marque qui nous a payés pour afficher ses publicités sur KEYM.</p>
            <p>Vous acceptez que nous puissions télécharger et installer des mises à jour du Service sur votre appareil.</p>
            <h2 id="10-droits-suppl-mentaires-que-nous-conservons">10. Droits supplémentaires que nous conservons</h2>
            <p>Si vous sélectionnez un nom d&#39;utilisateur ou un identifiant similaire pour votre compte, nous pouvons geler ou suspendre votre compte (par exemple, s&#39;il enfreint la propriété intellectuelle de quelqu&#39;un ou usurpe l&#39;identité d&#39;un autre utilisateur ou d&#39;un artiste). Ce droit de blocage ou de suspension est également réservé en cas de réclamation d&#39;un utilisateur ou d&#39;un tiers. Il sera de votre devoir de supprimer ou de modifier les informations pour que le compte obtienne un statut actif.</p>
            <p>Si vous utilisez du contenu couvert par des droits de propriété intellectuelle que nous détenons et mettons à disposition dans notre Service (par exemple, des images, des conceptions, des vidéos ou des sons que nous fournissons que vous ajoutez au contenu que vous créez ou partagez), nous conservons tous les droits sur notre contenu (mais pas le vôtre).</p>
            <p>Vous ne pouvez utiliser notre propriété intellectuelle et nos marques ou marques similaires que dans la mesure expressément autorisée par nos directives de marque ou avec notre autorisation écrite préalable.</p>
            <p>Vous devez obtenir une autorisation écrite de notre part ou sous une licence open source pour modifier, créer des œuvres dérivées, décompiler ou tenter d&#39;une autre manière d&#39;extraire le code source de notre part.</p>
            <h2 id="11-suppression-de-contenu-et-d-sactivation-ou-r-siliation-de-votre-compte">11. Suppression de contenu et désactivation ou résiliation de votre compte</h2>
            <p>Nous pouvons masquer tout contenu ou information que vous partagez sur le Service si nous pensons qu&#39;il enfreint les présentes Conditions d&#39;utilisation, nos politiques (y compris nos Lignes directrices de la communauté KEYM) ou si nous sommes tenus de le faire par la loi. Nous pouvons refuser de vous fournir ou d&#39;arrêter de vous fournir tout ou partie du Service (y compris la résiliation ou la désactivation de votre accès aux Services KEYM) immédiatement pour protéger notre communauté ou nos services, ou si vous créez un risque ou une exposition légale pour nous, violez ces Conditions d&#39;utilisation ou nos politiques (y compris nos directives communautaires KEYM), si vous enfreignez à plusieurs reprises les droits de propriété intellectuelle d&#39;autres personnes ou lorsque nous sommes tenus de le faire par la loi.</p>
            <p>Nous pouvons également résilier ou modifier le Service, masquer ou bloquer le contenu ou les informations partagées sur notre Service, ou cesser de fournir tout ou partie du Service si nous déterminons que cela est raisonnablement nécessaire pour éviter ou atténuer les impacts juridiques ou réglementaires défavorables sur nous. Dans certains cas, lorsque nous supprimons du contenu, nous vous informerons et vous expliquerons toutes les options dont vous disposez pour demander un autre examen, à moins que vous ne violiez gravement ou à plusieurs reprises les présentes Conditions, ou si cela peut nous exposer ou exposer d&#39;autres personnes à une responsabilité légale ; nuire à notre communauté d&#39;utilisateurs ; compromettre ou interférer avec l&#39;intégrité ou le fonctionnement de l&#39;un de nos services, systèmes ou produits ; où nous sommes limités en raison de limitations techniques ; ou lorsqu&#39;il nous est interdit de le faire pour des raisons légales. Si vous pensez que votre compte a été résilié par erreur, ou si vous souhaitez désactiver ou supprimer définitivement votre compte, consultez notre centre d&#39;aide (hello@keym-music.com).</p>
            <p>Lorsque vous demandez la suppression de contenu ou de votre compte, le processus de suppression ne commencera automatiquement pas plus de 30 jours après votre demande. La suppression du contenu peut prendre jusqu&#39;à 90 jours après le début du processus de suppression. Pendant que le processus de suppression de ce contenu est en cours, le contenu n&#39;est plus visible pour les autres utilisateurs, mais reste soumis aux présentes conditions d&#39;utilisation et à notre politique de données. Une fois le contenu supprimé, il peut nous falloir jusqu&#39;à 90 jours supplémentaires pour le supprimer des sauvegardes et des systèmes de reprise après sinistre.</p>
            <p>Le contenu ne sera pas supprimé dans les 90 jours suivant le début du processus de suppression du compte ou du contenu dans les situations suivantes :</p>
            <p>lorsque votre contenu a été utilisé par d&#39;autres conformément à cette licence et qu&#39;ils ne l&#39;ont pas supprimé (auquel cas cette licence continuera de s&#39;appliquer jusqu&#39;à ce que ce contenu soit supprimé) ; ou
                lorsque la suppression dans les 90 jours n&#39;est pas possible en raison des limitations techniques de nos systèmes, auquel cas, nous terminerons la suppression dès que cela sera techniquement possible ; ou
                où la suppression limiterait notre capacité à :
                enquêter ou identifier les activités illégales ou les violations de nos conditions et politiques (par exemple, pour identifier ou enquêter sur l&#39;utilisation abusive de nos produits ou systèmes) ;
                protéger la sûreté et la sécurité de nos produits, systèmes et utilisateurs ;
                se conformer à une obligation légale, telle que la conservation des preuves ; ou
                se conformer à une demande d&#39;une autorité judiciaire ou administrative, des forces de l&#39;ordre ou d&#39;un organisme gouvernemental ;
                dans ce cas, le contenu ne sera pas conservé plus longtemps que nécessaire aux fins pour lesquelles il a été conservé (la durée exacte variera au cas par cas).
                Si vous supprimez ou si nous désactivons votre compte, ces conditions prendront fin en tant qu&#39;accord entre vous et nous, mais cette section et la section ci-dessous intitulée &#39;Notre accord et ce qui se passe si nous ne sommes pas d&#39;accord&#39; s&#39;appliqueront toujours même après la résiliation de votre compte, désactivé ou supprimé.</p>
            <h2 id="12-notre-accord-et-que-se-passe-t-il-si-nous-ne-sommes-pas-d-accord">12. Notre accord et que se passe-t-il si nous ne sommes pas d&#39;accord</h2>
            <p>Votre utilisation de la musique sur le Service est soumise à votre utilisation de notre API est soumise à notre consentement préalable explicite, dont les termes doivent être négociés individuellement. Si vous utilisez certaines autres fonctionnalités ou services connexes, vous aurez la possibilité d&#39;accepter des conditions supplémentaires qui feront également partie de notre accord.</p>
            <p>Si un aspect de cet accord est inapplicable, le reste restera en vigueur.</p>
            <p>Toute modification ou renonciation à notre accord doit être écrite et signée par nous. Si nous ne parvenons pas à appliquer un aspect de cet accord, il ne s&#39;agira pas d&#39;une renonciation.</p>
            <p>Nous nous réservons tous les droits qui ne vous sont pas expressément accordés.</p>
            <h2 id="13-droits-de-tiers-et-transfert-de-droits-en-vertu-du-pr-sent-accord">13. Droits de tiers et transfert de droits en vertu du présent accord</h2>
            <p>Cet accord ne donne aucun droit à des tiers.</p>
            <p>Vous ne pouvez pas transférer vos droits ou obligations en vertu de cet accord sans notre consentement explicite.</p>
            <p>Nos droits et obligations peuvent être cédés à d&#39;autres. Par exemple, cela pourrait se produire si notre propriété change (comme dans le cas d&#39;une fusion, d&#39;une acquisition ou d&#39;une vente d&#39;actifs) ou par la loi.</p>
            <h2 id="14-responsabilit-">14. Responsabilité</h2>
            <p>Nous utiliserons une compétence et un soin raisonnables pour vous fournir notre Service et pour maintenir un environnement sûr, sécurisé et sans erreurs, mais nous ne pouvons pas garantir que notre Service fonctionnera toujours sans interruptions, retards ou imperfections. Sous réserve que nous ayons agi avec une compétence et une diligence raisonnables, nous n&#39;acceptons aucune responsabilité pour : les pertes non causées par notre violation des présentes Conditions ou autrement par nos actes ; les pertes qui ne sont pas raisonnablement prévisibles par vous et nous au moment de la conclusion des présentes Conditions ; tout contenu offensant, inapproprié, obscène, illégal ou autrement répréhensible publié par d&#39;autres que vous pourriez rencontrer sur notre Service ; et événements hors de notre contrôle raisonnable.</p>
            <p>Ce qui précède n&#39;exclut ni ne limite notre responsabilité en cas de décès, de blessures corporelles ou de fausses déclarations frauduleuses causées par notre négligence. Cela n&#39;exclut ni ne limite non plus notre responsabilité pour toute autre chose lorsque la loi ne nous permet pas de le faire.</p>
            <h2 id="15-d-saccord-et-diff-rends">15. Désaccord et différends</h2>
            <p>Si une réclamation ou un litige découle de ou se rapporte à votre utilisation du Service en tant que consommateur, vous et nous convenons que vous pouvez résoudre votre réclamation ou litige individuel contre nous, et nous pouvons résoudre notre réclamation ou litige contre vous, de quelque manière que ce soit, dans un tribunal compétent dans le pays de votre résidence principale qui a juridiction sur votre réclamation ou votre litige, et les lois de ce pays s&#39;appliqueront sans égard aux dispositions relatives aux conflits de lois.</p>
            <p>Si une réclamation ou un litige survient entre nous concernant l&#39;utilisation du Service à un autre titre, y compris, mais sans s&#39;y limiter, l&#39;accès ou l&#39;utilisation du Service à des fins commerciales ou commerciales, vous acceptez que toute réclamation ou litige doit être résolu par un tribunal compétent dans la région du Grand-Duché de Luxembourg et que le droit luxembourgeois s&#39;appliquera sans égard aux dispositions relatives aux conflits de lois et que tout ce qui est prévu dans les présentes ou les conditions spécifiques auxquelles il est fait référence est régi et interprété conformément aux lois luxembourgeoises.</p>
            <h2 id="16-mat-riel-non-sollicit-">16. Matériel non sollicité</h2>
            <p>Nous apprécions toujours les commentaires ou autres suggestions, mais nous pouvons les utiliser sans aucune restriction ni obligation de vous en dédommager, et nous n&#39;avons aucune obligation de les garder confidentiels.</p>
            <h2 id="17-mise-jour-de-ces-termes">17. Mise à jour de ces termes</h2>
            <p>Nous pouvons modifier notre service et nos politiques, et nous pouvons être amenés à apporter des modifications à ces conditions afin qu&#39;elles reflètent fidèlement notre service et nos politiques.</p>
            <p>Nous pouvons modifier les CGU à tout moment, notamment afin de tenir compte des modifications des lois ou règlements ou des évolutions des politiques de KEYM, des usages ou de la situation du marché.</p>
            <p>Sauf disposition contraire de la loi, nous vous informerons (par exemple, via notre Service) au moins 10 jours avant d&#39;apporter des modifications aux présentes Conditions et vous donnerons la possibilité de les examiner avant leur entrée en vigueur. Ensuite, si vous continuez à utiliser le Service, vous serez lié par les Conditions mises à jour. Si vous ne souhaitez pas accepter ces conditions ou toute mise à jour, vous pouvez supprimer votre compte à partir des paramètres de l&#39;application, en cliquant sur les paramètres du compte et en choisissant l&#39;option “supprimer le compte”.</p>
            <p><em>Version 11 mai 2023</em></p>
        </>
    )
}