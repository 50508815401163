import React from "react"
// Components
import FMScreen from "../components/animation/FMScreen/FMScreen"
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import CguFR from "../components/page-sections/cgu/bylang/CguFR";
import CguEN from "../components/page-sections/cgu/bylang/CguEN";
import i18n from "i18next";

export default function LegalMentionsPage() {

    const {t} = useTranslation();

    return (
        <div className="section-border-end pb-5 mb-5">
            <Container>
                <Row>
                    <Col className="text-center"><h1>{t("legal-mentions.title")}</h1></Col>
                </Row>
                <Row>
                    <Col>
                        { i18n.language === "fr" && <CguFR /> }
                        { i18n.language === "en" && <CguEN /> }
                    </Col>
                </Row>
            </Container>

            <FMScreen/>
        </div>
    )
}
