import picture1 from "../assets/images/app_screens_mockups/home.png";
import picture2 from "../assets/images/app_screens_mockups/profil.png";
import picture3 from "../assets/images/app_screens_mockups/messages.png";
import picture4 from "../assets/images/app_screens_mockups/recherches.png";
export const FEATURES = [
  {
    id: 1,
    title: "feature.feature_1.title",
    description: "feature.feature_1.description",
    picture :picture1
  },
  {
    id: 2,
    title: "feature.feature_2.title",
    description: "feature.feature_2.description",
    picture :picture2
  },
  {
    id: 3,
    title: "feature.feature_3.title",
    description: "feature.feature_3.description",
    picture :picture3
  },
  {
    id: 4,
    title: "feature.feature_4.title",
    description: "feature.feature_4.description",
    picture :picture4
  },
]
