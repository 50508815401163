export const textVariants = {
  hidden: {
    y: 300,
  },
  visible: (i) => ({
    y: 0,
    transition: {
      ease: "easeOut",
      duration: 0.5,
      delay: i * 0.3,
    },
  }),
}
