import React from "react"
import FMScreen from "../components/animation/FMScreen/FMScreen"
import Section from "../components/section/Section"

export default function NomatchPage() {
  return (
    <div>
      <Section></Section>
      <FMScreen />
    </div>
  )
}
