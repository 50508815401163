import React from "react"

export default function BrandingEN() {

    return (
        <>
            <h2 id="1-definition">1. Definition</h2>
            <p>Branded content on KEYM (hereinafter “Branded Content”) is content that promotes goods or services where you will receive (or have already received) something in return (money, services or goods) from a third party, such as a brand, in exchange for your post, or which you might otherwise need to disclose to comply with laws or regulations.</p>
            <h2 id="2-examples">2. Examples</h2>
            <p>Branded Content could include content posted by you, which features or references:
                A product or service that has been gifted to you by a brand, or that you have been paid to post about (whether in the form of money or a gift), or for which you will receive a commission on any sales (for instance, via an affiliate link or using a promotional code); or
                A brand that you have or have had a commercial relationship with, such as where you are acting as a brand ambassador.</p>
            <h2 id="3-rules-to-observe">3. Rules to observe</h2>
            <p>You are responsible for ensuring that any Branded Content that you post complies with all applicable laws and regulations. While the rules in this Branded Content Policy are intended to help you to comply with relevant laws and regulations, they are not exhaustive and you should be aware of any other applicable legal requirements.
                As well as following these rules when you post Branded Content, you must also comply with KEYM Terms of Use.
                You must ensure that the product or service you are promoting is sufficiently clear, without requiring other users to access your profile page or any links. For instance, you should explicitly identify the product or service verbally and/or in the text caption.</p>
            <h2 id="4-prohibitions">4. Prohibitions</h2>
            <h3 id="a-prohibited-actions">a. Prohibited actions</h3>
            <p>You must not post Branded Content which promotes products or services relating to any of prohibited industries listed below.
                We also recommend that you do not post Branded Content that violates any of the country-specific requirements.
                To the extent that your Branded Content does not comply with these rules, we may remove the content or impose other restrictions.</p>
            <h3 id="b-prohibited-industries">b. Prohibited Industries</h3>
            <p>You must not post Branded Content which promotes products or services from the following prohibited industries.</p>
            <ol>
                <li>Alcohol - Alcoholic beverages (wine, beer, spirits, etc.), alcohol clubs/subscription services, alcohol-making kits, or alcohol-sponsored events. This includes alcohol-free or no-alcohol alternatives and soft drinks presented as mixers for alcohol.</li>
                <li>Cigarettes and tobacco products - Cigarettes, cigars, tobacco, e-cigarettes, cigarette containers, shisha, and other related products and services.</li>
                <li>Drug-related products and services - Illegal drugs, drug paraphernalia, recreational and prescription drugs, CBD supplements.</li>
                <li>Weapons - Dangerous weapons, including guns, explosives, knives, swords, and other objects that are designed to cause harm to individuals.</li>
                <li>Gambling - Online gambling, casinos, sports betting, and Branded Content promoting fantasy sports, bingo, lotteries, or other gambling-related content.</li>
                <li>Sexual products and services - Adult entertainment and related paraphernalia including pornography, sex toys, lubricants, and fetish costumes, including some underwear like corsets and micro-thongs.</li>
                <li>Political content - Referencing, promoting, or opposing, a candidate for public office, current or former political leader, political party, or political organization. You must not advocate a stance (for or against) on a local, regional, or national issue of public importance to influence a political outcome.</li>
                <li>Products and services that enable dishonest behaviour - Products that may infringe upon individual privacy, products that may steal other people&#39;s personal information, products that may infringe upon a third party&#39;s properties, products that may steal other people&#39;s properties, or products or services assisting with falsification.</li>
                <li>Animals - Buying or selling animals, live animals, livestock, and pets. Animal body parts/products from rhino, orang-utan, elephant, or any endangered or threatened animals, including but not limited to organs, horns, ivory, bone, skin, fur, wool, leather, or teeth, except products for animals (toys, clothes, collars, cages, food, etc.), pet services like dog walking, pet care, pet cafes, pet grooming, training, etc., advertising content related to the adoption of animals is permitted for NGOs, NPOs, and stray animal shelters (no adoption services to be promoted for high breed pets or any invitation to join into mix-breeding of pets).</li>
                <li>Professional services - Accounting and taxation services, legal services, and immigration services.</li>
                <li>Pharmaceuticals, healthcare and medicine products - Including any pharmacological product claiming health benefits, over-the-counter medicines, prescription drugs, vitamins, and powders, shakes or gummies claiming to have vitamin or health benefits.</li>
                <li>Financial products or services or opportunities - Including foreign exchange, loans, credit cards, buy now pay later services, debt consolidation services, investment services, lending and management of money assets, trading platforms, pyramid schemes, multi-level marketing opportunities, credit repair, bail bonds, penny auctions, virtual currencies and “get rich quick” schemes.</li>
                <li>Contraceptive products - Any products, artificial methods, or other techniques to prevent pregnancy as a consequence of sexual intercourse, such as condoms except for family planning.</li>
                <li>Dating and live video applications or services - Dating services are services that are focused on generating connections between people who are interested in romance, casual sex, or friendship. Live video services are services where the primary feature is a live video chat.</li>
                <li>Age rated films, TV shows and games - Films, TV shows, and games that are restricted to certain audiences.</li>
                <li>Other prohibited products or services - International brides, hazardous chemical products, trading of human organs and transplants, abortion, services of prenatal sex determination, protected wildlife, wild flora, and funeral services.</li>
            </ol>
            <h2 id="5-miscellaneous">5. Miscellaneous</h2>
            <p>We may update these rules periodically (for instance, if we need to reflect any changes to applicable laws or regulations) and in some instances we may need to apply them to content you have already posted (for example, if a new law requires us to no longer display certain types of content).</p>
        </>
    )
}