/* eslint-disable react/forbid-prop-types */
import React from "react"
import PropTypes from "prop-types"
import style from "./faq-questions.scss"
import { FaqQuestionsByCategory } from "../../../../features/faq-questions-by-category"
import { Accordion } from "react-bootstrap"
import { useTranslation } from "react-i18next"

FaqQuestions.propTypes = {
    category: PropTypes.string.isRequired,
}
FaqQuestions.defaultProps = {
    category: "generals",
}

export default function FaqQuestions({ category }) {
    const { t } = useTranslation()

    return (
        <div className={style.container}>
            <Accordion defaultActiveKey={0}>
                {FaqQuestionsByCategory[category].map((question, index) => (
                    <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>
                            <h3 className={style.accordion_title}>
                                {t("faq.category." + category + "." + question + ".question")}
                            </h3>
                        </Accordion.Header>
                        <Accordion.Body
                            dangerouslySetInnerHTML={{
                                __html: t("faq.category." + category + "." + question + ".answer"),
                            }}
                        />
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    )
}
