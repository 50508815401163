import React from "react"
import style from "../carousel/Carousel.module.scss";
import {motion} from "framer-motion";
import arrow from "../../../../../assets/images/arrows/arrow.svg";
import hide from "../../../../../assets/images/icons/close_cross.svg";
import PropTypes from "prop-types";
import {Col, Row} from "react-bootstrap";
import styleProfile from "./profile.module.scss";
import {useTranslation} from "react-i18next";
import ProfileBio from "./profile-bio/ProfileBio";
import {profilePropstype} from "../../../../../propstype/profile.propstype";

Profile.propTypes = {
    card: profilePropstype.isRequired,
    handleChangeCurrent: PropTypes.func.isRequired,
    handleChangeCurrentPrev: PropTypes.func.isRequired,
}

export default function Profile({card, handleChangeCurrent, handleChangeCurrentPrev}) {

    const {t} = useTranslation();
    return (
        <motion.div
            className={style.carousel + " clickbox"}
            initial={{opacity: 0}}
            animate={{
                opacity: 1,
                transition: {
                    duration: 0.5,
                },
            }}
            exit={{
                opacity: 0,
                transition: {
                    duration: 1,
                },
            }}
        >
            <Row>
                <Col xs lg={{offset:1,span:5}} className="ps-5">
                    <motion.span layout onClick={(e) => handleChangeCurrentPrev(e)} role="button">
                        <motion.img className={styleProfile.prevBtn + " " + styleProfile.imgAction} src={arrow} layout
                                    alt=""/>
                    </motion.span>
                </Col>

                <Col xs lg={ 5 } className="pe-5">
                    <motion.span layout className={"float-end "} onClick={(e) => handleChangeCurrent(e)} role="button">
                        <motion.img className={styleProfile.imgAction} src={arrow} layout alt=""/>
                    </motion.span>
                </Col>
            </Row>

            <Row className={"pt-5 " + style.profileCard}>
                <Col xs={{offset: 0, span: 12}} lg={{offset: 2, span: 4}} className={"text-center"}>
                    <motion.img
                        src={card.imageNoBorder}
                        alt={`Photo de ${t(card.name)}`}
                        layout
                        className={styleProfile.picture}
                    />
                </Col>

                <Col lg={{offset: 1, span: 3}} className="pt-3 pt-sm-0 d-flex align-items-center">
                    <ProfileBio profile={card}/>
                </Col>
            </Row>

            <Row className="d-none d-sm-block">
                <Col xs lg={{span:11}}>
                    <motion.div layout className={"float-end pe-5"} role="button">
                        <motion.img className={styleProfile.imgAction} src={hide} layout alt=""/>
                    </motion.div>
                </Col>
            </Row>


        </motion.div>
    )
}