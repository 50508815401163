import React from "react"

export default function CguEN() {

    return (
        <>
            <p className={"text-center"}>KEYM the best way to experience musical arts !</p>
            <h2 id="1-scope">1. Scope</h2>
            <p>These terms of use (the “Terms” or the “Terms of Use”) govern the use of the KEYM Platform, except where expressly stated that separate terms apply, and provide information about the KEYM Service (the &quot;Service&quot;), outlined below. When you create a KEYM account or use KEYM, you agree to these Terms.</p>
            <p>Click here for a <a href={"contract"}>contract summary</a> and <a href={"eecc"}>here</a> for other information that we are required by the European Electronic Communications Code to provide.</p>
            <h2 id="2-who-we-are">2. Who we are</h2>
            <p>Gonly Sàrl is a company established under the laws of Luxembourg, with registered office in L-1470 Luxembourg, 17, route d’Esch, registered with the Trade and Companies Register of Luxembourg under the number B256133, which operates the “KEYM Platform” (“Gonly”).</p>
            <p>These Terms of Use therefore constitute an agreement between you and Gonly.</p>
            <h2 id="3-the-keym-service">3. The KEYM Service</h2>
            <p>We agree to provide you with the KEYM Service. The Service includes all of the KEYM products, features, applications, services, technologies and software that we provide to advance KEYM&#39;s objective: To share your passion on musical arts with the people having the same passion or interests.</p>
            <p>The KEYM Service has the following features:</p>
            <ul>
                <li>Offering personalised opportunities to create, connect, communicate, discover and share. People are different. So we offer you different features to help you create, share, grow your presence, and communicate with people on and off KEYM. We build systems that try to understand who and what you and others care about in musical arts, and use that information to help you create, find, join and share in experiences that matter to you. Part of that is highlighting content, features, offers and accounts that you might be interested in, and offering ways for you to experience KEYM, based on things that you and others do on and off KEYM.</li>
                <li>Promoting a positive, inclusive and safe environment.</li>
                <li>We endeavour to develop and use tools and offer resources to the community members that help to make their experiences positive and inclusive. We shall facilitate this experience though offering proactive help. We combat abuse and violations of our terms and policies, as well as harmful and deceptive behaviour. We use all of the information that we have – including your information – to endeavour to keep our platform secure. We also may share information about misuse or harmful content with local or European law enforcement bodies.</li>
                <li>Developing and using technologies that help us consistently serve our growing community.</li>
                <li>Creating and using cutting-edge technologies that help us personalise, protect and improve our Service on a large scale for a broad global community. Technologies such as Code languages, Growth Hacking and Agile methodologies give us the power to apply complex processes across our Service. Automated technologies also help us ensure the functionality and integrity of our Service.</li>
                <li>Providing consistent and seamless experiences across other products of Gonly or associated companies.</li>
                <li>Ensuring access to our Service.</li>
                <li>To operate our global Service, we must store and transfer data across our systems around the world, including outside of your country of residence. The use of this global infrastructure is necessary and essential to provide our Service. This infrastructure may be owned or operated by Gonly or their affiliates and will be located, unless otherwise required, on the European continent.</li>
                <li>Connecting you with bands, artists, brands, products and services in ways that you care about.</li>
                <li>We use data from KEYM, as well as from third-party partners, to show you ads, offers and other content that we believe will be meaningful to you. And we try to make that content as relevant as all of your other experiences on KEYM.</li>
                <li>We use the information that we have to develop, test and improve our Service and collaborate with others on research to make our Service better and contribute to the well-being of our community. This includes analysing the data that we have about our users and understanding how people use our Services, for example, by conducting surveys and testing and troubleshooting new features.</li>
            </ul>
            <h2 id="4-how-our-service-is-funded">4. How our Service is funded</h2>
            <p>Instead of paying to use KEYM, by using the Service covered by these Terms, you acknowledge that we can show you ads that businesses and organisations pay us to promote on and off the Meta Company Products as well as Google Ads. We use your personal data, such as information about your activity and interests, to show you ads that are more relevant to you.</p>
            <p>You may see branded content on KEYM posted by account holders who promote products or services based on a commercial relationship with the business partner mentioned in their content.</p>
            <h2 id="5-the-data-policy">5. The Data Policy</h2>
            <p>Providing our Service requires collecting and using your information. The Data Policy explains how we collect, use and share information across the Meta Products. It also explains the many ways that you can control your information, including in the KEYM privacy and security settings.</p>
            <h2 id="6-your-commitments">6. Your commitments</h2>
            <p>In return for our commitment to provide the Service, we require you to make the below commitments to us.</p>
            <h2 id="7-who-can-use-keym">7. Who can use KEYM</h2>
            <p>We want our Service to be as open and inclusive as possible, but we also want it to be safe, secure and in accordance with the law. So, we need you to commit to a few restrictions in order to be part of the KEYM community.</p>
            <ul>
                <li>You shall be at least 14 years old.</li>
                <li>You are note under a decision from a judicial or administrative authority denying you to receive any aspect of our Service under applicable laws.</li>
                <li>You are not listed on Bank services denial list (for example: liste des interdits bancaires).</li>
                <li>You were not previously disabled or banned from KEYM for violation of law, any of our policies or for sharing shocking or abusive content.</li>
                <li>You are not a convicted sex offender.</li>
                <li>You were not convicted for plagiarism or for infringement of copy rights.</li>
            </ul>
            <h2 id="8-key-aspects-for-using-keym">8. Key aspects for using KEYM</h2>
            <p>Providing a safe and open Service for a broad community requires that we all do our part.</p>
            <ul>
                <li>You cannot impersonate others or artists or provide inaccurate information.</li>
                <li>You do not have to disclose your identity on KEYM, but you must provide us with accurate and up-to-date information (including registration information), which may include providing personal data. Also, you may not impersonate someone or something that you aren&#39;t, and you cannot create an account for someone else unless you have their express permission.</li>
                <li>You cannot do anything unlawful, misleading or fraudulent or for an illegal or unauthorised purpose.</li>
                <li>You cannot violate (or help or encourage others to violate) these Terms or our policies.</li>
                <li>If you post <a href={"branding"}>branded content</a>, you must comply with our Branded Content Policies.</li>
                <li>You cannot do anything to interfere with or impair the intended operation of the Service.</li>
                <li>This includes misusing any reporting, dispute or appeals channel, such as by making fraudulent or groundless reports or appeals.</li>
                <li>You cannot attempt to create accounts or access or collect information in unauthorised ways.</li>
                <li>This includes creating accounts or collecting information in an automated way without our express permission.</li>
                <li>You cannot sell, license or purchase any account or data obtained from us or our Service.</li>
                <li>This includes attempts to buy, sell or transfer any aspect of your account (including your username); solicit, collect or use login credentials or badges of other users; or request or collect KEYM usernames, passwords or misappropriate access tokens.</li>
                <li>You cannot post someone else&#39;s private or confidential information without permission or do anything that violates someone else&#39;s rights, including intellectual property rights (e.g. copyright infringement, trademark infringement, counterfeit or pirated goods).</li>
                <li>You may use someone else&#39;s works under exceptions or limitations to copyright and related rights under applicable law. You represent that you own or have obtained all necessary rights to the content that you post or share. Please note that other users or any Government or State Body can file an infringement claim against you with us and this may lead, after an appropriate review, to the immediate removal of the post.</li>
                <li>You cannot modify, translate, create derivative works of or reverse-engineer our products or their components.</li>
                <li>You cannot use a domain name or URL in your username without our prior written consent.</li>
            </ul>
            <h2 id="9-permissions-by-you-as-a-user">9. Permissions by you as a user</h2>
            <p>As part of our agreement, you also give us permissions that we need to provide the Service.</p>
            <p>We do not claim ownership of your content, but you grant us a licence to use it for advertising purposes of the KEYM Service.</p>
            <p>We do not claim ownership of your content that you post on or through the Service and you are free to share your content with anyone else, wherever you choose. However, we need certain legal permissions from you (known as a &quot;licence&quot;) to provide the Service. When you share, post or upload content that is covered by intellectual property rights (such as audio, music, photos or videos) on or in connection with our Service, you hereby grant to us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide licence to host, use, distribute, modify, run, copy, publicly perform or display, translate and create derivative works of your content (consistent with your privacy and application settings). This licence will end when your content is deleted from our systems. You can delete content individually or all at once by deleting your account.</p>
            <p>You give us permission to show your username, profile picture and information about your actions (such as likes) or relationships (such as follows) next to or in connection with accounts, ads, offers and other sponsored content. For example, we may show that you liked a sponsored post created by a brand that has paid us to display its ads on KEYM.</p>
            <p>You agree that we can download and install updates to the Service on your device.</p>
            <h2 id="10-additional-rights-we-retain">10. Additional rights we retain</h2>
            <p>If you select a username or similar identifier for your account, we may freeze or suspend your account (for example, if it infringes someone&#39;s intellectual property or impersonates another user or an artist). This right to freeze or suspend is also reserved in the event of a complaint from a user or a third party. It will be your duty to remove or change the information for the account to gain an active status.</p>
            <p>If you use content covered by intellectual property rights that we have and make available in our Service (for example, images, designs, videos or sounds we provide that you add to content you create or share), we retain all rights to our content (but not yours).</p>
            <p>You can only use our intellectual property and trademarks or similar marks as expressly permitted by our Brand Guidelines or with our prior written permission.</p>
            <p>You must obtain written permission from us or under an open-source licence to modify, create derivative works of, decompile or otherwise attempt to extract source code from us.</p>
            <h2 id="11-content-removal-and-disabling-or-terminating-your-account">11. Content removal and disabling or terminating your account</h2>
            <p>We can hide any content or information that you share on the Service if we believe that it violates these Terms of Use, our policies (including our KEYM Community Guidelines) or we are required to do so by law. We can refuse to provide or stop providing all or part of the Service to you (including terminating or disabling your access to the KEYM Services) immediately to protect our community or services, or if you create risk or legal exposure for us, violate these Terms of Use or our policies (including our KEYM Community Guidelines), if you repeatedly infringe other people&#39;s intellectual property rights or where we are required to do so by law.</p>
            <p>We can also terminate or change the Service, hide or block content or information shared on our Service, or stop providing all or part of the Service if we determine that doing so is reasonably necessary to avoid or mitigate adverse legal or regulatory impacts on us. In some cases, when we remove content, we&#39;ll let you know and explain any options you have to request another review, unless you seriously or repeatedly violate these Terms, or if doing so may expose us or others to legal liability; harm our community of users; compromise or interfere with the integrity or operation of any of our services, systems or products; where we are restricted due to technical limitations; or where we are prohibited from doing so for legal reasons. If you believe that your account has been terminated in error, or you want to disable or permanently delete your account, consult our help center (hello@keym-music.com).</p>
            <p>When you request to delete content or your account, the deletion process will automatically begin no more than 30 days after your request. It may take up to 90 days to delete content after the deletion process begins. While the deletion process for such content is being undertaken, the content is no longer visible to other users, but remains subject to these Terms of Use and our Data Policy. After the content is deleted, it may take us up to another 90 days to remove it from backups and disaster recovery systems.</p>
            <p>Content will not be deleted within 90 days of the account deletion or content deletion process beginning in the following situations:</p>
            <ul>
                <li>where your content has been used by others in accordance with this licence and they have not deleted it (in which case this licence will continue to apply until that content is deleted); or</li>
                <li>where deletion within 90 days is not possible due to technical limitations of our systems, in which case, we will complete the deletion as soon as technically feasible; or</li>
                <li>where deletion would restrict our ability to:</li>
                <li>investigate or identify illegal activity or violations of our terms and policies (for example, to identify or investigate misuse of our products or systems);</li>
                <li>protect the safety and security of our products, systems and users;</li>
                <li>comply with a legal obligation, such as the preservation of evidence; or</li>
                <li>comply with a request of a judicial or administrative authority, law enforcement or a government agency;</li>
                <li>in which case, the content will be retained for no longer than is necessary for the purposes for which it has been retained (the exact duration will vary on a case-by-case basis).</li>
                <li>If you delete or we disable your account, these Terms shall terminate as an agreement between you and us, but this section and the section below called “Our Agreement and What Happens if We Disagree&quot; will still apply even after your account is terminated, disabled or deleted.</li>
            </ul>
            <h2 id="12-our-agreement-and-what-happens-if-we-disagree">12. Our agreement and What Happens if We Disagree</h2>
            <p>Your use of music on the Service is subject to your use of our API is subject to our explicit prior consent, which terms shall be individually negotiated. If you use certain other features or related services, you will be provided with an opportunity to agree to additional terms that will also become a part of our agreement.</p>
            <p>If any aspect of this agreement is unenforceable, the rest will remain in effect.</p>
            <p>Any amendment or waiver to our agreement must be in writing and signed by us. If we fail to enforce any aspect of this agreement, it will not be a waiver.</p>
            <p>We reserve all rights not expressly granted to you.</p>
            <h2 id="13-third-party-rights-and-transfer-of-rights-under-this-agreement">13. Third party rights and transfer of rights under this agreement</h2>
            <p>This agreement does not give rights to any third parties.</p>
            <p>You cannot transfer your rights or obligations under this agreement without our explicit consent.</p>
            <p>Our rights and obligations can be assigned to others. For example, this could occur if our ownership changes (as in a merger, acquisition or sale of assets) or by law.</p>
            <h2 id="14-liability">14. Liability</h2>
            <p>We will use reasonable skill and care in providing our Service to you and in keeping a safe, secure and error-free environment, but we cannot guarantee that our Service will always function without disruptions, delays or imperfections. Provided that we have acted with reasonable skill and care, we do not accept responsibility for: losses not caused by our breach of these Terms or otherwise by our acts; losses which are not reasonably foreseeable by you and us at the time of entering into these Terms; any offensive, inappropriate, obscene, unlawful or otherwise objectionable content posted by others that you may encounter on our Service; and events beyond our reasonable control.</p>
            <p>The above does not exclude or limit our liability for death, personal injury or fraudulent misrepresentation caused by our negligence. It also does not exclude or limit our liability for any other things where the law does not permit us to do so.</p>
            <h2 id="15-disagreement-and-disputes">15. Disagreement and disputes</h2>
            <p>If a claim or dispute arises out of or relates to your use of the Service as a consumer, both you and us agree that you may resolve your individual claim or dispute against us, and we may resolve our claim or dispute against you, in any competent court in the country of your main residence that has jurisdiction over your claim or dispute, and the laws of that country will apply without regard to conflict of law provisions.</p>
            <p>If a claim or dispute arises between us that relates to use of the Service in any other capacity, including, but not limited to, access or use of the Service for a business or commercial purpose, you agree that any such claim or dispute must be resolved in a competent court in the district of Luxembourg, Grand-Duchy of Luxembourg and that Luxembourg law will apply without regard to conflict of law provisions and anything provided herein or the specific terms referred to are governed by and interpreted according to the laws of Luxembourg.</p>
            <h2 id="16-unsolicited-material">16. Unsolicited material</h2>
            <p>We always appreciate feedback or other suggestions, but may use them without any restrictions or obligation to compensate you for them, and are under no obligation to keep them confidential.</p>
            <h2 id="17-updating-these-terms">17. Updating these terms</h2>
            <p>We may change our Service and Policies, and we may need to make changes to these Terms so that they accurately reflect our Service and Policies.</p>
            <p>We may amend the Terms at any time, particularly in order to take account of amendments to the laws or regulations or changes in KEYM policies, the customs or the market situation.</p>
            <p>Unless otherwise required by law, we will notify you (for example, through our Service) at least 10 days before we make changes to these Terms and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Terms. If you do not want to agree to these or any updated Terms, you can delete your account from the Settings of the app, clicking on the Account settings and choosing the option “delete account”.</p>
            <p><em>Version 11 May 2023</em></p>
        </>
    )
}