import React from "react"

export default function PoliticEN() {

    return (
        <>
            <p>This privacy policy defines and informs you of the way in which the company GONLY S.à r.l., a limited liability company, incorporated and existing under the laws of the Grand Duchy of Luxembourg, having its registered office at 17, route d&#39; Esch, L-1470 Luxembourg, registered with the Luxembourg Trade and Companies Register under number B256133, which operates the KEYM social network presented on this website (“we”) uses and protects the information you provide to us when you download our application described on our website <a href="https://keym-music.com/">https://keym-music.com/</a>.</p>
            <p>By downloading our app, you agree to our Privacy Policy and Terms of Service set out below.</p>
            <p>We reserve the right, at our discretion, to modify or withdraw portions of this Privacy Policy at any time and for any reason. In order to be informed how we process and protect your personal data, we recommend that you periodically consult this policy on our website <a href="https://keym-music.com/">https://keym-music.com/</a>.</p>
            <p>This Privacy Policy is in addition to any other terms and conditions applicable to our Site, App or any other contract you may enter into with us.</p>
            <p>The use of third party sites (which may be linked to our website) is at your own risk. In this case, we invite you to consult the confidentiality and privacy policy of these third-party sites.</p>
            <h3 id="what-personal-data-do-we-process-">What personal data do we process?</h3>
            <p>We collect some of your personal data when you download and use our app.
                The information collected, without this list being exhaustive, is as follows:</p>
            <ul>
                <li>Your first and last name,</li>
                <li>Your gender</li>
                <li>Your profession as well as the level of this profession,</li>
                <li>Your email address,</li>
                <li>Your city where you live,</li>
                <li>Your phone number,</li>
                <li>Your musical tastes.
                    If you are acting on behalf of your company, additional data is collected in relation to your company, however, this data is not considered personal data within the meaning of the general data protection regulations of April 27, 2016 (hereinafter referred to as “<strong>the GDPR</strong>”).
                    When you download our application or if you contact us for any reason, we record and store the data mentioned above as well as, if applicable, this correspondence.</li>
            </ul>
            <h3 id="how-do-we-use-your-personal-information-">How do we use your personal information?</h3>
            <p>Your personal data will be collected and processed for the following purposes:</p>
            <ul>
                <li>To enable you to download and use our application, therefore to provide you with the service you expect,</li>
                <li>To respond to your questions and requests when you contact us,</li>
                <li>To allow us to create, publish and improve the most relevant content for you,</li>
                <li>To allow you to benefit from the interactive features of our application,</li>
                <li>To carry out statistical and analytical studies and research on our potential customers and candidates on the content and services/products of interest to them,</li>
                <li>To further develop and improve our app to serve you better,</li>
                <li>If necessary, to exercise and/or defend a right before the competent authority,</li>
                <li>To investigate or respond to incidents and complaints,</li>
                <li>Where necessary, to comply with any legal obligation,</li>
            </ul>
            <p>Our use of your personal information as described above is permitted by applicable data protection law because it is:</p>
            <ul>
                <li>necessary for our legitimate interests in the pursuit of the purposes set out above, these interests may prevail over the protection of your privacy,</li>
                <li>necessary to comply with our legal and regulatory responsibilities, such as disclosure to competent authorities, regulatory bodies or public institutions,</li>
                <li>necessary for the performance of a contract to which you are a party or for the performance of pre-contractual measures taken at your request, or</li>
                <li>in certain circumstances, carried out with your consent, which you transmit to us periodically.
                    The retention period of your personal data depends on the purpose of the processing of this data and the tool used for their processing. The criteria used to determine the applicable data retention period are as follows: we will retain the personal information defined in this policy for as long (i) as necessary to fulfil the intended purposes, (ii) as necessary for the purposes of our professional relationship with you, (iii) that you consent to it and/or (iv) that applicable data retention laws and regulations require it.</li>
            </ul>
            <p>GONLY S.à r.l., a limited liability company, incorporated and existing under the laws of the Grand Duchy of Luxembourg, having its registered office at 17, route d&#39;Esch, L-1470 Luxembourg, registered in the Trade and des Sociétés de Luxembourg under number B256133 is responsible for processing personal data.</p>
            <p>The processing of your data will be carried out by persons appointed by us and who perform functions related to this processing. Any person who may be ordered by us to process your personal data will have signed an enhanced privacy statement. However, we periodically use different subcontractors to perform certain functions in our name and on our behalf. By way of example, this may be the case for the hosting and/or maintenance of the content of the website and the application or the provision of certain functionalities on the website and the application. These suppliers are established in Europe/EEA and Vietnam. Third-party recipients to whom we pass your data will have access to it only to the extent required to perform their functions, and are not permitted to use your personal information for any other purpose. These recipients will be subject to contractual confidentiality obligations.</p>
            <p>Disclosure to government or law enforcement authorities may be made if we determine, in our sole discretion, that we are legally required to do so.</p>
            <p>The disclosure of your personal data thus constitutes, in certain cases, a necessary condition for the processing carried out for the above purposes.</p>
            <p>Therefore, in the event of non-disclosure of your personal data and/or your opposition to it, we are not able to provide the services, nor fulfil any associated obligation under applicable law.</p>
            <h3 id="how-do-we-ensure-the-security-of-your-data">How do we ensure the security of your data</h3>
            <p>We endeavour to take all steps reasonably necessary to ensure that your personal data is treated securely and in accordance with this policy. This includes reviewing and updating our security measures to reflect current technologies. However, we draw your attention to the fact that the transmission of information via the Internet is, unfortunately, never completely secure. We cannot therefore guarantee the security of your personal data communicated when downloading the application or to any third party and, for this reason, any transmission of data is your sole responsibility. We will use strict operational procedures and adequate technical and organizational security measures to prevent unauthorized access, modification, deletion or transmission of such data. In addition, our employees and all independent service providers who provide services related to our information systems are required to respect the confidentiality of personal information held by us. However, we cannot be held responsible for events resulting from unauthorized access to your personal information.
                What are your rights in relation to your personal data?
                Under applicable data protection laws, including the GDPR, your rights are:</p>
            <ul>
                <li><u>Right to access and obtain a copy of your personal data:</u> You are entitled to request confirmation that we are processing any personal information relating to you. If this is the case, you can access your personal data and certain information relating to its processing. In some cases, we may provide you, upon your request, with an electronic copy of your data.</li>
                <li><u>Right to rectification of your personal data:</u> If you can demonstrate that the information we hold about you is incorrect, you can request that it be updated or otherwise rectified.</li>
                <li><u>Right to be forgotten/right to deletion of data:</u> In certain circumstances, you are entitled to request the deletion of your personal data. You can make this request at any time and we will decide what to do. However, this right is subject to any rights or obligations that may be attributed to us by law, in terms of data retention. When we determine, in accordance with the law, that your request is admissible, we will proceed to the deletion of your personal data, as soon as possible.</li>
                <li><u>Right to withdraw consent:</u> Insofar as the processing of your personal data is based on your consent, you also have the right to withdraw this consent, at any time. The withdrawal of your consent will not affect the legality of any processing based on your consent that would have been carried out prior to said withdrawal.</li>
                <li><u>Right to data portability:</u> In specific cases specified in the GDPR, you have the right to receive all personal data concerning you and provided to us and to transmit them to another controller. We reserve the right to charge fees for such a transfer, in particular in the event of frequent requests and/or in the event of a request deemed to be excessive in terms of the volume of data concerned.</li>
                <li><u>Right to complain:</u> You also have the possibility of lodging a complaint concerning the processing of your personal data with the national authority in charge of data protection: the National Commission for Data Protection (“CNPD”).
                    If you wish to exercise your rights, do not hesitate to contact us at the following e-mail address: <a href="mailto:hello@keym-music.com">hello@keym-music.com</a>.</li>
            </ul>
            <h3 id="how-to-reach-us-in-case-of-questions-">How to reach us in case of questions?</h3>
            <p>Questions, comments and requests regarding this policy are welcome and should be directed to the following email address: <a href="mailto:hello@keym-music.com">hello@keym-music.com</a>. </p>

        </>
    )
}