import React from "react"

export default function EeccEN() {

    return (
        <>
            <p>The European Electronic Communications Code (Directive (EU) 2018/1972) (the &quot;EECC&quot;) requires us to provide applicable users with the following information about the electronic communication service that we provide as part of our messaging services, including chat, voice and video calls. This information supplements the terms of service for those services. Applicable users include microenterprises or small enterprises or not-for-profit organisations, unless they have explicitly agreed to waive all or parts of those provisions.</p>
            <p>For the purposes of your documentation, future reference and unchanged reproduction, you may download or print a copy of this document.</p>
            <h2 id="1-our-quality-of-service">1. Our quality of service</h2>
            <p>Although we endeavour to provide you the best service, we cannot guarantee any minimum levels of quality of service and make no guarantees that our messaging services will always function without disruptions, delay or errors.</p>
            <p>A number of factors beyond our control may also affect your experience in using our messaging services, and may result in the disruption of your communication with other users, including but not limited to: your local network, firewall, your Internet service provider, the public Internet and power supply.</p>
            <h2 id="2-price">2. Price</h2>
            <p>We do not charge for using our messaging services. All users of our messaging services are responsible for network provider data plans, Internet access fees and other fees and taxes associated with use of the service.</p>
            <h2 id="3-duration">3. Duration</h2>
            <p>Our users can terminate their relationship with our service at any time for any reason by deleting their account. We may modify, suspend or terminate your access to or use of our messaging services for the reasons described in the KEYM Terms of Use.</p>
            <h2 id="4-the-security-we-provide-on-our-messaging-services">4. The security we provide on our messaging services</h2>
            <p>We work to protect the safety, security and integrity of our messaging services by appropriately dealing with abusive people and activity violating our Terms of Use. We work to prohibit misuse of our service, including harmful conduct towards others. We operate systems to improve our ability to detect incidents, threats or vulnerabilities that may harm the security of our service. If we learn of activity like this, we will take appropriate action, such as removing that activity or contacting law enforcement.</p>
            <h2 id="5-data-provided-by-you">5. Data provided by you</h2>
            <p>When you sign up for our KEYM service as a new user, we ask you to provide your birthday (so that we may provide the right experience for your age) and your email address or mobile number. You can find more information on the data that is collected and processed by KEYM in the context of the provision of the service in our Data Policy.</p>
            <h2 id="6-features-for-end-users-with-disabilities">6. Features for end users with disabilities</h2>
            <p>Our messaging services are compatible with mobile accessibility functionality, such as providing the ability to adjust various on-screen contrast, colours and text size options.</p>
            <h2 id="7-information-on-handling-disputes">7. Information on handling disputes</h2>
            <p>If you are unhappy with our messaging services, you can raise a complaint by writing to us at:</p>
            <p>Gonly Sàrl
                17, route d’Esch
                L-1470 Luxembourg
                G-D of Luxembourg
                E-Mail: hello@keym-music.com</p>
            <p>You may also report an issue with your use of KEYM by reporting it by contacting us at the above address.</p>
            <p>You can take legal action against us for any claim that arises out of, or relates to, our messaging services. For information on which governing law and venue applies to your claim, please refer to the &quot;Disagreement and disputes&quot; section of KEYM Terms of Use.</p>
            <p>Lastly, you may be entitled to refer disputes in relation to matters covered by the EECC to an independent body for dispute resolution appointed in your country. A list of competent independent bodies for dispute resolution in EU countries and links to their websites is available at the European Union&#39;s Online Dispute Resolution website: Online Dispute Resolution - European Commission (<a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>).</p>
            <h2 id="8-you-cannot-access-emergency-services-through-our-messaging-services">8. You cannot access emergency services through our messaging services</h2>
            <p>There are important differences between our services and your mobile phone and a fixed-line telephone and SMS services. Our services do not provide access to emergency services or emergency services providers, including the police, fire departments or hospitals, or otherwise connect to public safety answering points. You should ensure that you can contact your relevant emergency services providers through a mobile phone, a fixed-line telephone or another service.</p>

        </>
    )
}