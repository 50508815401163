import React from "react"
// Components
import FMScreen from "../components/animation/FMScreen/FMScreen"
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import BrandingTitle from "../components/page-sections/branding/title/BrandingTitle";
import BrandingFR from "../components/page-sections/branding/bylang/BrandingFR";
import BrandingEN from "../components/page-sections/branding/bylang/BrandingEN";

export default function BrandingPage() {

    const {i18n} = useTranslation();

    return (
        <div className="section-border-end pb-5 mb-5">
            <Container>
                <Row>
                    <Col><BrandingTitle/></Col>
                </Row>
                <Row>
                    <Col>
                        { i18n.language === "fr" && <BrandingFR /> }
                        { i18n.language === "en" && <BrandingEN /> }
                    </Col>
                </Row>
            </Container>

            <FMScreen/>
        </div>
    )
}
