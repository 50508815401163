import React from "react"
import { useTranslation } from "react-i18next"
import style from "./section-contact.module.scss"
import { Col, Container, Row } from "react-bootstrap"
import CardCustom from "../../../card/CardCustom"
import { StoreLinks } from "../../../../features/store-links"
import googleBtn from "../../../../assets/images/logos/google_play.svg"
import appleBtn from "../../../../assets/images/logos/apple.svg"
import imgArrow from "../../../../assets/images/download_arrow.svg"

export default function SectionContact() {
    const { t } = useTranslation()
    const appleLink = StoreLinks.apple
    const googleLink = StoreLinks.google

    const isMobile = window.innerWidth <= 768

    return (
        <div className={style.section_contact}>
            <Container fluid>
                <Row>
                    <Col xs={{ offset: 0 }} sm={{ offset: 1 }}>
                        <Row className={"pb-5"}>
                            <Col lg={8} xs={12}>
                                <Row>
                                    <Col>
                                        <CardCustom text={[]} title={[t("contact.title_1"), t("contact.title_2")]} />
                                    </Col>
                                </Row>
                                <Row className={"pt-2"}>
                                    <p className={style.cta}>
                                        <img className="pb-1" src={imgArrow} alt="*" />
                                        {t("contact.title_3")}
                                    </p>
                                </Row>
                                <Row className={isMobile ? "pt-3" : "pt-5"}>
                                    <Col>
                                        <div className="container-sm">
                                            <Row className={isMobile ? "px-4" : ""}>
                                                <Col className="mb-2  px-0" lg={{ span: 4 }} xs={{ span: 6 }}>
                                                    <a href={googleLink}>
                                                        <img
                                                            className={style.storeBtn}
                                                            src={googleBtn}
                                                            alt="Keym Google Play"
                                                        />
                                                    </a>
                                                </Col>
                                                <Col className="mb-2 px-0" lg={{ span: 4 }} xs={{ span: 6 }}>
                                                    <a href={appleLink}>
                                                        <img
                                                            className={style.storeBtn}
                                                            src={appleBtn}
                                                            alt="Keym Appstore"
                                                        />
                                                    </a>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
