import React from "react"

export default function BrandingFR() {

    return (
        <>
            <h2 id="1-d-finition">1. Définition</h2>
            <p>Le contenu de marque sur KEYM (ci-après « contenu de marque ») est un contenu qui fait la promotion de biens ou de services pour lesquels vous recevrez (ou avez déjà reçu) quelque chose en retour (argent, services ou biens) d&#39;un tiers, tel qu&#39;une marque, en échange pour votre message, ou que vous pourriez autrement avoir besoin de divulguer pour vous conformer aux lois ou réglementations.</p>
            <h2 id="2-exemples">2. Exemples</h2>
            <p>Le contenu de marque peut inclure du contenu que vous publiez, qui présente ou fait référence :
                Un produit ou un service qui vous a été offert par une marque, ou pour lequel vous avez été payé pour publier (que ce soit sous forme d&#39;argent ou de cadeau), ou pour lequel vous recevrez une commission sur toute vente (par exemple, via un lien affilié ou en utilisant un code promotionnel) ; ou
                Une marque avec laquelle vous avez ou avez eu une relation commerciale, par exemple lorsque vous agissez en tant qu&#39;ambassadeur de la marque.</p>
            <h2 id="3-r-gles-respecter">3. Règles à respecter</h2>
            <p>Vous êtes responsable de vous assurer que tout contenu de marque que vous publiez est conforme à toutes les lois et réglementations applicables. Bien que les règles de cette politique de contenu de marque aient pour but de vous aider à vous conformer aux lois et réglementations applicables, elles ne sont pas exhaustives et vous devez être conscient de toute autre exigence légale applicable.
                En plus de suivre ces règles lorsque vous publiez du contenu de marque, vous devez également vous conformer aux conditions d&#39;utilisation de KEYM.
                Vous devez vous assurer que le produit ou le service dont vous faites la promotion est suffisamment clair, sans obliger les autres utilisateurs à accéder à votre page de profil ou à des liens. Par exemple, vous devez identifier explicitement le produit ou service verbalement et/ou dans la légende du texte.</p>
            <h2 id="4-interdictions">4. Interdictions</h2>
            <h3 id="a-actions-interdites">a. Actions interdites</h3>
            <p>Vous ne devez pas publier de contenu de marque faisant la promotion de produits ou de services liés à l&#39;une des industries interdites répertoriées ci-dessous.
                Nous vous recommandons également de ne pas publier de contenu de marque qui viole l&#39;une des exigences spécifiques à chaque pays.
                Dans la mesure où votre contenu de marque ne respecte pas ces règles, nous pouvons supprimer le contenu ou imposer d&#39;autres restrictions.</p>
            <h3 id="b-industries-interdites">b. Industries interdites</h3>
            <p>Vous ne devez pas publier de contenu de marque faisant la promotion de produits ou de services provenant des industries interdites suivantes.</p>
            <ol>
                <li>Alcool - Boissons alcoolisées (vin, bière, spiritueux, etc.), clubs d&#39;alcool/services d&#39;abonnement, kits de fabrication d&#39;alcool ou événements parrainés par l&#39;alcool. Cela inclut les alternatives sans alcool ou sans alcool et les boissons non alcoolisées présentées comme des mélangeurs pour l&#39;alcool.</li>
                <li>Cigarettes et produits du tabac - Cigarettes, cigares, tabac, cigarettes électroniques, contenants de cigarettes, chicha et autres produits et services connexes.</li>
                <li>Produits et services liés à la drogue - Drogues illégales, accessoires de consommation de drogue, drogues récréatives et sur ordonnance, suppléments de CBD.</li>
                <li>Armes - Armes dangereuses, y compris les fusils, les explosifs, les couteaux, les épées et autres objets conçus pour blesser des personnes.</li>
                <li>Jeux d&#39;argent - Jeux d&#39;argent en ligne, casinos, paris sportifs et contenu de marque faisant la promotion de sports fantastiques, de bingo, de loteries ou d&#39;autres contenus liés aux jeux d&#39;argent.</li>
                <li>Produits et services sexuels - Divertissements pour adultes et accessoires connexes, y compris la pornographie, les jouets sexuels, les lubrifiants et les costumes fétichistes, y compris certains sous-vêtements comme les corsets et les micro-strings.</li>
                <li>Contenu politique - Faire référence, promouvoir ou s&#39;opposer à un candidat à une fonction publique, à un dirigeant politique actuel ou ancien, à un parti politique ou à une organisation politique. Vous ne devez pas défendre une position (pour ou contre) sur une question locale, régionale ou nationale d&#39;importance publique pour influencer un résultat politique.</li>
                <li>Produits et services qui permettent un comportement malhonnête - Produits susceptibles de porter atteinte à la vie privée, produits susceptibles de voler les informations personnelles d&#39;autres personnes, produits susceptibles de porter atteinte aux propriétés d&#39;un tiers, produits susceptibles de voler les propriétés d&#39;autrui ou produits ou services aidant à la falsification .</li>
                <li>Animaux - Achat ou vente d&#39;animaux, d&#39;animaux vivants, de bétail et d&#39;animaux de compagnie. Parties/produits du corps d&#39;animaux provenant de rhinocéros, d&#39;orangs-outans, d&#39;éléphants ou de tout animal en voie de disparition ou menacé, y compris, mais sans s&#39;y limiter, les organes, les cornes, l&#39;ivoire, les os, la peau, la fourrure, la laine, le cuir ou les dents, à l&#39;exception des produits pour animaux (jouets, vêtements, colliers, cages, nourriture, etc.), les services pour animaux de compagnie comme la promenade de chiens, les soins pour animaux de compagnie, les cafés pour animaux de compagnie, le toilettage d&#39;animaux, la formation, etc., le contenu publicitaire lié à l&#39;adoption d&#39;animaux est autorisé pour les ONG, les OBNL et les refuges pour animaux errants (pas de services d&#39;adoption à promouvoir pour les animaux de grande race ni d&#39;invitation à participer à des croisements d&#39;animaux de compagnie).</li>
                <li>Services professionnels - Services de comptabilité et de fiscalité, services juridiques et services d&#39;immigration.</li>
                <li>Produits pharmaceutiques, produits de santé et médicaments - Y compris tout produit pharmacologique revendiquant des bienfaits pour la santé, les médicaments en vente libre, les médicaments sur ordonnance, les vitamines et les poudres, shakes ou gommes revendiquant des vitamines ou des bienfaits pour la santé.</li>
                <li>Produits, services ou opportunités financiers - Y compris les devises, les prêts, les cartes de crédit, les services d&#39;achat immédiat, les services de consolidation de dettes, les services d&#39;investissement, le prêt et la gestion d&#39;actifs monétaires, les plateformes de négociation, les systèmes pyramidaux, les opportunités de marketing à plusieurs niveaux, la réparation de crédit , les cautionnements, les enchères à un centime, les monnaies virtuelles et les programmes « devenez riche rapidement ».</li>
                <li>Produits contraceptifs - Tous les produits, méthodes artificielles ou autres techniques pour prévenir la grossesse à la suite d&#39;un rapport sexuel, tels que les préservatifs, à l&#39;exception de la planification familiale.</li>
                <li>Applications ou services de rencontre et de vidéo en direct - Les services de rencontre sont des services axés sur la création de liens entre les personnes intéressées par la romance, le sexe occasionnel ou l&#39;amitié. Les services de vidéo en direct sont des services dont la fonctionnalité principale est un chat vidéo en direct.</li>
                <li>Films, émissions de télévision et jeux classés selon l&#39;âge - Films, émissions de télévision et jeux réservés à certains publics.</li>
                <li>Autres produits ou services interdits - Mariées internationales forcées, produits chimiques dangereux, commerce d&#39;organes humains et de greffes, avortement, services de détermination prénatale du sexe, faune protégée, flore sauvage et services funéraires.</li>
            </ol>
            <h2 id="5-divers">5. Divers</h2>
            <p>Nous pouvons mettre à jour ces règles périodiquement (par exemple, si nous devons refléter toute modification des lois ou réglementations applicables) et, dans certains cas, nous devrons peut-être les appliquer au contenu que vous avez déjà publié (par exemple, si une nouvelle loi nous oblige à n&#39;affichent plus certains types de contenu).</p>


        </>
    )
}