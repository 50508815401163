import React from "react"
import { Col, Row } from "react-bootstrap"
import Image from "react-bootstrap/Image"
import style from "./social-icons.module.scss"
import imgInstagram from "../../assets/images/social/bouton_insta.svg"
import imgFacebook from "../../assets/images/social/bouton_facebook.svg"
import imgLinkedIN from "../../assets/images/social/bouton_linkedin.svg"
import imgMail from "../../assets/images/social/bouton_mailto.svg"
import { socialPropstype } from "../../propstype/social.propstype"

SocialIcons.propTypes = {
    socialNetworks: socialPropstype,
}

SocialIcons.defaultProps = {
    socialNetworks: {
        instagram: { url: "https://www.instagram.com/keym.music" },
        facebook: { url: "https://www.facebook.com/keymxmusic" },
        linkedIN: { url: "https://www.linkedin.com/company/keym-music" },
        mail: { url: "hello@keym-music.com" },
    },
}

export default function SocialIcons({ socialNetworks }) {
    const { instagram, facebook, linkedIN, mail } = socialNetworks
    const socialMedias = [
        {
            label: "Instagram",
            image: imgInstagram,
            url: instagram.url,
        },
        {
            label: "Facebook",
            image: imgFacebook,
            url: facebook.url,
        },
        {
            label: "LinkedIN",
            image: imgLinkedIN,
            url: linkedIN?.url ?? "",
        },
        {
            label: "Mail",
            image: imgMail,
            url: mail?.url ?? "",
        },
    ]

    return (
        <Row>
            {socialMedias
                .filter((item) => item.url !== "")
                .map((item, index) => (
                    <Col md={{ span: 3 }} xs={{ span: 2 }} key={item.label}>
                        <a key={index} href={item.url}>
                            <Image className={style.socialIcon} alt={item.label} src={item.image} />
                        </a>
                    </Col>
                ))}
        </Row>
    )
}
