import React from "react"
import PropTypes from "prop-types"
import style from "./MenuButton.module.scss"
import { motion } from "framer-motion"

MenuButton.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onChangeIsOpen: PropTypes.func.isRequired,
}

export default function MenuButton({ isOpen, onChangeIsOpen }) {
    const variant = isOpen ? "opened" : "closed"
    const animation = { originX: 1, transition: { type: "linear", ease: "easeOut" } }

    const top = {
        closed: { ...animation, rotate: 0 },
        opened: { ...animation, rotate: -45 },
    }

    const bottom = {
        closed: { ...animation, rotate: 0 },
        opened: { ...animation, rotate: 45 },
    }

    return (
        <div className={style.container} onClick={onChangeIsOpen}>
            <motion.span variants={top} initial={closed} animate={variant} />
            <motion.span variants={bottom} initial={closed} animate={variant} />
        </div>
    )
}
