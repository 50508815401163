import React from "react"

export default function PoliticFR() {

    return (
        <>
            <p>La présente politique de confidentialité définit et vous informe de la manière dont la société GONLY S.à r.l., une société à responsabilité limitée, constituée et existante sous les lois du Grand-Duché de Luxembourg, ayant son siège social au 17, route d’Esch, L-1470 Luxembourg, enregistrée au Registre de Commerce et des Sociétés de Luxembourg sous le numéro B256133, qui exploite le réseau social KEYM présenté sur le présent site-internet (« nous ») utilise et protège les informations que vous nous transmettez lorsque vous téléchargez notre application décrite sur notre site internet <a href="https://keym-music.com/">https://keym-music.com/</a>.</p>
            <p>En téléchargeant notre application, vous acceptez notre politique de confidentialité et les conditions d&#39;utilisation énoncées ci-dessous.</p>
            <p>Nous nous réservons le droit, à notre discrétion, de modifier ou de retirer des parties de cette politique de confidentialité à tout moment et pour quelque raison que ce soit. Afin d’être informé comment nous traitons et protégeons vos données à caractère personnel, nous vous recommandons de consulter périodiquement la présente politique sur notre site Internet <a href="https://keym-music.com/">https://keym-music.com/</a>.</p>
            <p>Cette politique de confidentialité s&#39;ajoute à toutes les autres modalités et conditions applicables à notre site, à notre application ou à tout autre contrat que vous pourriez éventuellement conclure avec nous.</p>
            <p>L&#39;utilisation de sites tiers (qui peuvent être liés à notre site web) s&#39;effectue sous votre responsabilité. Nous vous invitons dans ce cas à consulter la politique de confidentialité et de vie privée de ces sites tiers.</p>
            <h3 id="quelles-donn-es-personnelles-traitons-nous-">Quelles données personnelles traitons-nous ?</h3>
            <p>Nous recueillons certaines de vos données personnelles lorsque vous téléchargez et utilisez notre application.
                Les informations recueillies, sans que cette liste ne soit exhaustive, sont les suivantes :</p>
            <ul>
                <li>Votre prénom et nom,</li>
                <li>Votre genre,</li>
                <li>Votre métier ainsi que le niveau de ce métier,</li>
                <li>Votre adresse e-mail,</li>
                <li>Votre ville dans laquelle vous habitez,</li>
                <li>Votre numéro de téléphone,</li>
                <li>Vos goûts musicaux.</li>
            </ul>
            <p>Si vous agissez au nom de votre entreprise, des données supplémentaires sont récoltées par rapport à votre entreprise, néanmoins, ces données ne sont pas considérées comme des données personnelles au sens du règlement général sur la protection des données du 27 avril 2016 (ci-après dénommé « le RGPD »).</p>
            <p>Lorsque vous téléchargez notre application ou si vous nous contactez pour quelque raison que ce soit, nous enregistrons et conservons les données mentionnées ci-dessus ainsi que, le cas échéant, cette correspondance.</p>
            <h3 id="comment-utilisons-nous-vos-informations-personnelles-">Comment utilisons-nous vos informations personnelles ?</h3>
            <p>Vos données personnelles seront collectées et traitées aux fins suivantes :</p>
            <ul>
                <li>Pour vous permettre le téléchargement et l’utilisation de notre application, par conséquent, pour vous fournir le service que vous attendez,</li>
                <li>Pour répondre à vos questions et demandes lorsque vous nous contactez,</li>
                <li>Pour nous permettre de créer, publier et améliorer le contenu le plus pertinent pour vous,</li>
                <li>Pour vous permettre de bénéficier des fonctionnalités interactives de notre application,</li>
                <li>Pour réaliser des études et des recherches statistiques et analytiques sur nos clients potentiels et candidats sur le contenu et les services/produits qui les intéressent,</li>
                <li>Pour développer et améliorer davantage notre application afin de mieux vous servir,</li>
                <li>Si nécessaire, pour exercer et/ou défendre un droit auprès de l’autorité compétente,</li>
                <li>Pour enquêter ou répondre aux incidents et aux plaintes,</li>
                <li>Le cas échéant, pour se conformer à toute obligation légale,</li>
            </ul>
            <p>Notre utilisation de vos informations personnelles telle que décrite ci-dessus est autorisée par la loi en vigueur en matière de protection des données, car elle est :</p>
            <ul>
                <li>nécessaire pour nos intérêts légitimes dans la poursuite des finalités exposées précédemment, ces intérêts pouvant prévaloir sur la protection de votre vie privée,</li>
                <li>nécessaire au respect de nos responsabilités légales et réglementaires, telles que la divulgation aux autorités compétentes, aux organismes de réglementation ou aux institutions publiques,</li>
                <li>nécessaire à l’exécution d’un contrat auquel vous êtes partie ou à l’exécution de mesures précontractuelles prises à votre demande, ou
                    dans certaines circonstances, effectuée avec votre consentement, que vous nous transmettez périodiquement.</li>
            </ul>
            <p>La durée de conservation de vos données personnelles dépend de la finalité du traitement de ces données et de l’outil utilisé pour leur traitement. Les critères utilisés pour déterminer la période de conservation des données applicable sont les suivants : nous conserverons les informations personnelles définies dans la présente politique aussi longtemps (i) que nécessaire pour atteindre les objectifs prévus, (ii) que nécessaire aux fins de notre relation professionnelle avec vous, (iii) que vous y consentirez et/ou (iv) que l’exigent les lois et réglementations applicables en matière de conservation des données.</p>
            <p>La société GONLY S.à r.l., une société à responsabilité limitée, constituée et existante sous les lois du Grand-Duché de Luxembourg, ayant son siège social au 17, route d’Esch, L-1470 Luxembourg, enregistrée au Registre de Commerce et des Sociétés de Luxembourg sous le numéro B256133 est le responsable du traitement des données à caractère personnel.</p>
            <p>Le traitement de vos données sera effectué par des personnes désignées par nous et qui occupent des fonctions liées à ce traitement. Toute personne qui pourra être ordonnée par nous de traiter vos données personnelles aura signé une déclaration de confidentialité renforcée. Cependant, nous faisons périodiquement appel à différents sous-traitants afin qu’ils remplissent certaines fonctions en notre nom et pour notre compte. À titre d’exemple, cela pourra être le cas pour l’hébergement et/ou la maintenance du contenu du site Internet et de l’application ou encore la fourniture de certaines fonctionnalités sur le site internet et l’application. Ces fournisseurs sont établis en Europe/EEE et au Vietnam. Les destinataires tiers auxquels nous transmettons vos données auront accès à ces dernières uniquement dans la mesure requise pour l’exécution de leurs fonctions, et ne sont pas autorisés à utiliser vos informations personnelles à toute autre fin. Ces destinataires seront soumis à des obligations de confidentialité contractuelles.</p>
            <p>Une divulgation aux autorités gouvernementales ou chargées de l’application des lois peut être faite si nous déterminons, à notre entière discrétion, que nous sommes légalement tenus de procéder ainsi.</p>
            <p>La divulgation de vos données personnelles constitue, ainsi, dans certains cas, une condition nécessaire pour le traitement effectué aux finalités ci-dessus.</p>
            <p>Par conséquent, en cas de non-divulgation de vos données personnelles et/ou de votre opposition à celle-ci, nous ne sommes pas en mesure d&#39;assurer les services, ni remplir toute obligation associée en vertu de la loi applicable.</p>
            <h3 id="comment-garantissons-nous-la-s-curit-de-vos-donn-es">Comment garantissons-nous la sécurité de vos données</h3>
            <p>Nous nous efforçons de prendre toutes les mesures raisonnablement nécessaires afin de garantir que vos données personnelles font l’objet d’un traitement sécurisé et conforme à la présente politique. Pour ce faire, nous passons notamment en revue et nous mettons à jour nos mesures de sécurité en fonction des technologies actuelles. Néanmoins, nous attirons votre attention sur le fait que la transmission d’informations via Internet n’est, malheureusement, jamais complètement sécurisée. Nous ne pouvons donc garantir la sécurité de vos données personnelles communiquées lors du téléchargement de l’application ou à tout tiers et, pour cette raison, toute transmission de données relève de votre seule responsabilité. Nous utiliserons des procédures opérationnelles strictes ainsi que des mesures de sécurité technique et organisationnelle adéquates, afin d’empêcher tout accès, toute modification, suppression ou transmission non autorisés desdites données. En outre, nos salariés ainsi que tous les prestataires indépendants qui fournissent des services liés à nos systèmes d&#39;information sont tenus de respecter la confidentialité des renseignements personnels détenus par nos soins. Cependant, nous ne pouvons être tenus responsables des événements résultant de l&#39;accès non autorisé à vos informations personnelles.</p>
            <h3 id="quels-sont-vos-droits-par-rapport-vos-donn-es-personnelles-">Quels sont vos droits par rapport à vos données personnelles ?</h3>
            <p>En vertu des lois applicables relatives à la protection des données, et notamment du RGPD, vos droits sont les suivants :</p>
            <ul>
                <li>Droit d’accès et d’obtention d’une copie de vos données personnelles : Vous êtes autorisé à demander confirmation du fait que nous traitons toute information personnelle vous concernant. Si tel est le cas, vous pouvez accéder à vos données personnelles et à certaines informations relatives à leur traitement. Dans certains cas, nous pourrons vous fournir, sur demande de votre part, une copie électronique de vos données.</li>
                <li>Droit de rectification de vos données personnelles : Si vous pouvez démontrer que les informations que nous détenons à votre sujet sont incorrectes, vous pouvez demander qu’elles soient mises à jour ou autrement rectifiées.</li>
                <li>Droit à l’oubli/droit de suppression des données : Dans certaines circonstances, vous êtes autorisé à demander la suppression de vos données personnelles. Vous pouvez faire cette demande à tout moment et nous déciderons des suites à donner. Toutefois, ce droit est soumis à tous droits ou obligations qui pourraient nous être attribués par la loi, en matière de conservation des données. Lorsque nous déterminerons, conformément à la loi, que votre demande est recevable, nous procéderons à la suppression de vos données personnelles, dans les meilleurs délais.</li>
                <li>Droit de retirer un consentement : Dans la mesure où le traitement de vos données personnelles est basé sur votre consentement, vous avez également le droit de retirer ce consentement, à tout moment. Le retrait de votre consentement n’aura pas d’incidence sur la légalité de tout traitement basé sur votre consentement qui aurait été effectué antérieurement audit retrait.</li>
                <li>Droit à la portabilité des données : Dans des cas particuliers précisés dans le RGPD, vous disposez du droit de recevoir toutes les données à caractère personnel vous concernant et fournies à nous et de les transmettre à un autre responsable du traitement. Nous nous réservons le droit de facturer des frais pour un tel transfert, notamment en cas de demandes fréquentes et / ou en cas d’une demande jugée comme excessive au niveau du volume de données concernées.</li>
                <li>Droit à la plainte : Vous avez également la possibilité de déposer une plainte concernant le traitement de vos données personnelles auprès de l’autorité nationale chargée de la protection des données : la Commission Nationale pour la Protection des Données (“CNPD”).</li>
                <li>Si vous désirez exercer vos droits, n’hésitez pas à nous contacter à l’adresse e-mail suivante : hello@keym-music.com.</li>
            </ul>
            <h3 id="comment-nous-joindre-en-cas-de-questions-">Comment nous joindre en cas de questions ?</h3>
            <p>Les questions, commentaires et demandes concernant cette politique sont les bienvenus et doivent être adressés à l’adresse e-mail suivante : hello@keym-music.com.</p>
        </>
    )
}