/* eslint-disable react/prop-types */
import React, { createContext, useState } from "react"

export const SectionContext = createContext()

export default function SectionProvider({ children }) {
  const [activeLink, setActiveLink] = useState(null)

  function onChangeActiveLink(link) {
    setActiveLink(link)
  }

  return <SectionContext.Provider value={{ activeLink, onChangeActiveLink }}>{children}</SectionContext.Provider>
}
