import React from "react"
import style from "./section-banner.module.scss"
import { Container, Row } from "react-bootstrap"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"
import imgStar from "../../../../assets/images/home-star.svg"
export default function SectionBanner() {
    const { t } = useTranslation()

    return (
        <div id="home" className={style.sectionbanner + " d-flex align-items-center"}>
            <Container fluid>
                <Row>
                    <div className={style.col_first}>
                        <motion.h1
                            className={style.title}
                            initial={{ x: 600 }}
                            animate={{
                                x: 0,
                                transition: {
                                    ease: "easeOut",
                                    duration: 0.5,
                                    delay: 0.3,
                                },
                            }}
                        >
                            TOGETHER
                        </motion.h1>
                    </div>
                </Row>

                <Row>
                    <div className={style.col_second}>
                        <motion.h1
                            className={style.title}
                            initial={{ x: -600 }}
                            animate={{
                                x: 0,
                                transition: {
                                    ease: "easeOut",
                                    duration: 0.5,
                                    delay: 0.3,
                                },
                            }}
                        >
                            SOUNDS
                        </motion.h1>
                    </div>
                </Row>

                <Row>
                    <div className="position-absolute">
                        <motion.h1
                            className={style.col_icon}
                            initial={{ x: -600 }}
                            animate={{
                                x: 0,
                                transition: {
                                    ease: "easeOut",
                                    duration: 0.5,
                                    delay: 0.3,
                                },
                            }}
                        >
                            <img className={style.star_icon} src={imgStar} alt="*" />
                        </motion.h1>
                    </div>
                    <div className={style.col_third}>
                        <motion.h1
                            className={style.title}
                            initial={{ x: 600 }}
                            animate={{
                                x: 0,
                                opacity: [
                                    1, 1, 1, 1, 0.5, 1, 0.5, 1, 1, 1, 1, 1, 0.5, 1, 1, 1, 1, 0.5, 1, 0.5, 1, 1, 1, 0.8,
                                    1, 0.8, 1,
                                ],
                                transition: {
                                    opacity: { duration: 3.5, delay: 1.5 },
                                    ease: "easeOut",
                                    duration: 0.5,
                                    delay: 0.3,
                                },
                            }}
                        >
                            BETTER
                        </motion.h1>
                    </div>
                </Row>

                <Row>
                    <div className={style.col_text}>
                        <div className={style.bannertext}>{[t("banner.text")]}</div>
                    </div>
                </Row>
            </Container>
        </div>
    )
}
