import React from "react"
import style from "./section-download.module.scss"
import logo from "../../../../assets/images/logo_bg_black.svg"
import googleBtn from "../../../../assets/images/logos/google_play.svg"
import appleBtn from "../../../../assets/images/logos/apple.svg"
import MotionParallaxPhone from "../../../animation/MotionParallaxPhone/MotionParallaxPhone"
import { useTranslation } from "react-i18next"
import { Container, Col, Row } from "react-bootstrap"
import CardCustom from "../../../card/CardCustom"
import { StoreLinks } from "../../../../features/store-links"

export default function SectionDownload() {
    const { t } = useTranslation()
    const appleLink = StoreLinks.apple
    const googleLink = StoreLinks.google

    const isMobile = window.innerWidth <= 768

    return (
        <div className={style.sectionDownload + " pt-5 d-flex align-items-center"}>
            <Container fluid>
                <Row>
                    <Col xs={{ offset: 0, span: 12 }} lg={{ offset: 1, span: 5 }}>
                        <Row>
                            <Col>
                                <img src={logo} alt="logo" />
                            </Col>
                        </Row>
                        <Row className="pt-5">
                            <Col>
                                <CardCustom
                                    text={[t("app.description")]}
                                    title={[t("app.title_1"), t("app.title_2")]}
                                />
                            </Col>
                        </Row>
                        <Row className={isMobile ? "pt-3 px-4" : "pt-5"}>
                            <Col xs={{ span: 6 }} sm={{ span: 6 }} className="text-center mb-2 px-0" role="button">
                                <a href={googleLink}>
                                    <img className={style.storeBtn} src={googleBtn} alt="Keym Google Play" />
                                </a>
                            </Col>
                            <Col xs={{ span: 6 }} sm={{ span: 6 }} className="text-center mb-2  px-0" role="button">
                                <a href={appleLink}>
                                    <img className={style.storeBtn} src={appleBtn} alt="Keym Appstore" />
                                </a>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={{ offset: 0, span: 12 }} lg={{ span: 6 }}>
                        <MotionParallaxPhone />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
