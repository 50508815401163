import React, { useState } from "react"
import style from "./contact-form.module.scss"
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import imgAsterisque from "../../../../../assets/images/icons/icon_mic_active.svg"
import imgStar from "../../../../../assets/images/home-star.svg"

export default function ContactForm() {
    const { t } = useTranslation()
    const [validated, setValidated] = useState(false)
    const initialFormData = Object.freeze({
        name: "",
        email: "",
        topic: "",
        message: "",
    })
    const [formData, updateFormData] = React.useState(initialFormData)
    const initialContactResult = Object.freeze({
        success: false,
        failure: false,
    })
    const [formResult, updateFormResult] = React.useState(initialContactResult)

    const handleChange = (e) => {
        updateFormData({
            ...formData,

            [e.target.name]: e.target.value.trim(),
        })
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget
        event.preventDefault()
        event.stopPropagation()

        if (form.checkValidity() === false) {
            return false
        }

        setValidated(true)

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData),
        }

        fetch(process.env.REACT_APP_API_URL + "contact", requestOptions).then(async (response) => {
            const res = await response.json()

            updateFormResult({
                ...formResult,
                success: res.success,
                failure: !res.success,
            })
        })
    }

    return (
        <div className={style.contactFormContainer}>
            <img className={style.imageStar} src={imgStar} alt="*" />
            <Container fluid>
                <Row>
                    <Col className={style.formBg} xs sm={{ offset: 2, span: 8 }}>
                        <h3 className="mb-3">
                            {[t("contact_us.title")]} <img className="ps-1" src={imgAsterisque} alt="*" />
                        </h3>

                        <Form noValidate validated={validated} onSubmit={handleSubmit} className="mt-5 text-left">
                            <Row className="mb-5">
                                <Col xs={{ span: 12 }} sm={{ span: 6 }}>
                                    <Form.Group controlId="contactUsFormName">
                                        <Form.Label>{t("contact_us.your_name_label")}*</Form.Label>
                                        <Form.Control
                                            name="name"
                                            required
                                            type="text"
                                            placeholder=""
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col xs={{ span: 12 }} sm={{ span: 6 }}>
                                    <Form.Group controlId="contactUsFormEmail">
                                        <Form.Label>{t("contact_us.your_email_label")}*</Form.Label>
                                        <Form.Control
                                            name="email"
                                            required
                                            type="email"
                                            placeholder=""
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mb-5">
                                <Col xs={{ span: 12 }} sm={{ span: 6 }}>
                                    <Form.Group controlId="contactUsFormTopic">
                                        <Form.Label>{t("contact_us.contact_topic_label")}*</Form.Label>
                                        <Form.Select
                                            name="topic"
                                            required
                                            defaultValue={t("contact_us.select")}
                                            onChange={handleChange}
                                        >
                                            <option value="">{t("contact_us.select")}</option>
                                            <option value={t("contact_us.app_problem")}>
                                                {t("contact_us.app_problem")}
                                            </option>
                                            <option value={t("contact_us.suggestions")}>
                                                {t("contact_us.suggestions")}
                                            </option>
                                            <option value={t("contact_us.career")}>{t("contact_us.career")}</option>
                                            <option value={t("contact_us.partnership")}>
                                                {t("contact_us.partnership")}
                                            </option>
                                            <option value={t("contact_us.various")}>{t("contact_us.various")}</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col className="d-none d-sm-block"></Col>
                            </Row>

                            <Row className="mb-5">
                                <Col>
                                    <Form.Group className="mb-3" controlId="contactUsFormMessage">
                                        <Form.Label>{t("contact_us.message_label")}</Form.Label>
                                        <Form.Control name="message" as="textarea" rows={3} onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                                    {formResult.failure && (
                                        <Alert key={"danger"} variant={"danger"}>
                                            {t("contact_us.failure")}
                                        </Alert>
                                    )}
                                    {formResult.success && (
                                        <Alert key={"success"} variant={"success"}>
                                            {t("contact_us.success")}
                                        </Alert>
                                    )}
                                </Col>

                                <Col xs={{ span: 12 }} sm={{ span: 4 }} className="text-end">
                                    <Button type="submit">{t("contact_us.send_button_label").toUpperCase()}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
