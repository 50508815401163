import React, { useMemo } from "react"
import style from "./feature.module.scss"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { AnimatePresence, motion } from "framer-motion"
import { Col, Container, Row } from "react-bootstrap"

Feature.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    currentIdShow: PropTypes.number.isRequired,
}

export default function Feature({ id, title, description, currentIdShow }) {
    const { t } = useTranslation()

    const animateDescription = useMemo(() => {
        if (currentIdShow === id) return "visible"
        if (currentIdShow > id) return "exit"

        return "hidden"
    }, [currentIdShow])

    return (
        <AnimatePresence>
            <motion.div animate={animateDescription} variants={descriptionVariants} initial="hidden" exit="exit">
                <div className={style.feature}>
                    <Container fluid>
                        <Row>
                            <Col>
                                <h1>{t(title)}</h1>
                            </Col>
                        </Row>
                        <Row className={"pt-2"}>
                            <Col lg={10}>
                                <p style={{ lineHeight: 1.2 }}>{t(description)}</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </motion.div>
        </AnimatePresence>
    )
}
const descriptionVariants = {
    hidden: {
        opacity: 0,
        y: 200,
        transition: {
            duration: 0.7,
        },
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 2,
        },
    },
    exit: {
        opacity: 0,
        y: -200,
        zIndex: -1,
        transition: {
            duration: 0.7,
        },
    },
}
