import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import style from "../rp/rp.module.scss"
import { Container, Col, Row } from "react-bootstrap"
import CardCustom from "../../../card/CardCustom"
import Carousel from "./carousel/Carousel"

export default function SectionTeam() {
    const { t } = useTranslation()
    const [selectedCard, setSelectedCard] = useState(null)

    return (
        <div className={(selectedCard !== null ? style.isSelected : "") + " " + style.sectionTeam}>
            <Container fluid className={style.teamSectionContainer}>
                <Row>
                    <Col xs={{ offset: 0, span: 12 }} lg={{ offset: 1, span: 5 }}>
                        <CardCustom
                            title={["", t("team.title_1"), t("team.title_2")]}
                            text={[t("team.description_1"), t("team.description_2")]}
                        />
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col>
                        <Container fluid>
                            <Carousel
                                selectedCard={selectedCard}
                                onChangeSelectedCard={(card) => setSelectedCard(card)}
                            />
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
