import React from "react"
import { Col, Container, ProgressBar, Row } from "react-bootstrap"
import PropTypes from "prop-types"
import FeaturePicture from "../feature-picture/FeaturePicture"
import style from "./feature-pagination.module.scss"

FeaturePagination.propTypes = {
    features: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            picture: PropTypes.string.isRequired,
        })
    ).isRequired,
    currentFeature: PropTypes.number.isRequired,
    position: PropTypes.number.isRequired, //0 = end (top) of the feature view, 1) end (bottom) of feature view
}

export default function FeaturePagination({ features, currentFeature, position }) {
    const isMobile = window.innerWidth <= 768

    const getPercent = () => {
        const sizeParts = 1 / features.length
        const borneInf = currentFeature * sizeParts
        const percentOfFeature = ((position - borneInf) / sizeParts) * 100

        if (percentOfFeature < 20) return 0
        if (percentOfFeature < 40) return 20
        if (percentOfFeature < 80) return 80

        return 100
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Row>
                        {/*{[t("feature.title_1"), t("feature.title_2")].map((title, i) =>*/}
                        {/*    <motion.h2 key={i} variants={textVariants}>*/}
                        {/*        {title}*/}
                        {/*    </motion.h2>*/}
                        {/*)*/}
                        {/*}*/}
                    </Row>
                </Col>
            </Row>
            <Row className="pt-lg-5">
                <Col lg={8}>
                    {isMobile && (
                        <div className="pt-2 pb-2">
                            <FeaturePicture
                                picture={features[currentFeature] ? features[currentFeature].picture : ""}
                            />
                        </div>
                    )}
                    <div className="d-flex align-items-center bd-highlight pt-4">
                        {features.map((f, i) => (
                            <>
                                <div key={i}>
                                    <div
                                        key={f.id}
                                        className={
                                            (currentFeature === i ? style.numberActive : style.number) + " ps-2 pe-2"
                                        }
                                    >
                                        {f.id}
                                    </div>
                                </div>
                                {i < features.length - 1 && (
                                    <span
                                        key={f.id.i}
                                        className={currentFeature === i ? "progressActive" : "progressHide"}
                                    >
                                        <ProgressBar now={getPercent()} />
                                    </span>
                                )}
                            </>
                        ))}
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
