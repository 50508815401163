import React, { useEffect, useRef, useState } from "react"
import Video from "../../../video/video"
import { useTranslation } from "react-i18next"
import { Container, Col, Row } from "react-bootstrap"
import CardCustom from "../../../card/CardCustom"
import style from "./section-pitch.module.scss"
import { useInView } from "framer-motion"

export default function SectionPitch() {
    const { t } = useTranslation()
    const containerRef = useRef(null)
    const [playing, setPlaying] = useState(false)
    const isInView = useInView(containerRef, { amount: 1 })
    useEffect(() => {
        if (isInView) {
            setPlaying(true)
        }
    }, [isInView])

    return (
        <div ref={containerRef} className={style.sectionpitch + " d-flex align-items-center"}>
            <Container fluid>
                <Row>
                    <Col
                        lg={{ offset: 1, span: 5 }}
                        xs={{ offset: 0, span: 12 }}
                        className={"d-flex align-items-center"}
                    >
                        <CardCustom
                            firstTextBigger={true}
                            text={[
                                t("project.description.p1"),
                                t("project.description.p2"),
                                t("project.description.p3"),
                            ]}
                            textSpan={{ lg: 10 }}
                            title={[t("project.title_1"), t("project.title_2")]}
                        />
                    </Col>
                    <Col lg={{ offset: 0, span: 5 }} xs={{ offset: 0, span: 12 }}>
                        <Video playing={playing} />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
