import React, { useState } from "react"
import style from "./menu.module.scss"
import { MENU } from "../../../lib/menu"
import { motion } from "framer-motion"
import classnames from "classnames"
import { HashLink } from "react-router-hash-link"
import smoothscroll from "smoothscroll-polyfill"

// Translation
import { useTranslation } from "react-i18next"

export default function Menu() {
    const { t } = useTranslation()
    smoothscroll.polyfill()
    const [isHoverParent, setIsHoverParent] = useState(null)

    return (
        <ul className={style.container}>
            {MENU.map((item) => (
                <motion.div
                    key={item.id}
                    initial="init"
                    whileHover={{ scale: 1.2 }}
                    animate={isHoverParent === item.id ? "hover" : "init"}
                    className={" pt-3"}
                >
                    <motion.li
                        className={classnames(style.parent)}
                        variants={parentVariants}
                        onMouseEnter={() => setIsHoverParent(item.id)}
                        onMouseLeave={() => setIsHoverParent(null)}
                    >
                        <HashLink smooth to={item.to} spy="true" offset={-150} duration={2000}>
                            <>
                                <span>{t(item.name).toUpperCase()}</span>
                            </>
                        </HashLink>
                    </motion.li>
                </motion.div>
            ))}
        </ul>
    )
}

const parentVariants = {
    init: {
        color: "white",
    },
    hover: {
        color: "#FFFF54",
    },
}
