import React from "react";
import PropTypes from "prop-types";
import style from './feature-picture.module.scss';

FeaturePicture.propTypes = {
    picture: PropTypes.string,
};

FeaturePicture.defaultProps = {
    picture: ''
}

export default function FeaturePicture({picture}) {
    const isMobile = window.innerWidth <= 768;
    return (
        <div className={style.featurePicture + " d-flex align-items-center justify-content-center"}>
            {picture !== '' && <img src={picture} alt={"picture of feature"} width={isMobile ? 100 : 275}/>}
        </div>

    )
}