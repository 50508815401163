import React from "react"
import {Route, Routes, useLocation} from "react-router-dom"
// Components
import {AnimatePresence} from "framer-motion"
import Header from "./components/header/Header"
import SectionProvider from "./lib/context/sectionContext"
// Routes
import HomePage from "./pages/home"
import NomatchPage from "./pages/nomatch"
import Footer from "./components/footer/Footer";
import FaqPage from "./pages/faq";
import CguPage from "./pages/cgu";
import LegalMentionsPage from "./pages/legal-mentions";
import PoliticPage from "./pages/politic";
import ContractPage from "./pages/contract";
import EeccPage from "./pages/eecc";
import BrandingPage from "./pages/branding";
import {RemoveTrailingSlash} from "./features/remove-trailing-slash";

export default function App() {
    const location = useLocation()
    return (
        <SectionProvider>
            <Header/>

            <AnimatePresence mode="wait">
                <RemoveTrailingSlash />
                <Routes location={location} key={location.pathname}>
                    <Route path="/branding" element={<BrandingPage/>}/>
                    <Route path="/cgu" element={<CguPage/>}/>
                    <Route path="/faq" element={<FaqPage/>}/>
                    <Route path="/legal-mentions" element={<LegalMentionsPage/>}/>
                    <Route path="/politic" element={<PoliticPage/>}/>
                    <Route path="/politics" element={<PoliticPage/>}/>
                    <Route path="/contract" element={<ContractPage/>}/>
                    <Route path="/eecc" element={<EeccPage/>}/>
                    <Route path="/fr" element={<HomePage/>}/>
                    <Route path="/en" element={<HomePage/>}/>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="*" element={<NomatchPage/>}/>
                </Routes>
            </AnimatePresence>

            <Footer/>
        </SectionProvider>
    )
}
