import instagram from "../assets/images/instagram.svg"
import facebook from "../assets/images/facebook.svg"
import linkedin from "../assets/images/linkedin.svg"
import twitter from "../assets/images/twitter.svg"

export const medias = [
  {
    id: "instagram",
    image: instagram,
    link: "https://www.instagram.com/?hl=fr",
  },
  {
    id: "facebook",
    image: facebook,
    link: "https://fr-fr.facebook.com/",
  },
  {
    id: "linkedin",
    image: linkedin,
    link: "https://www.linkedin.com/feed/",
  },
  {
    id: "twitter",
    image: twitter,
    link: "https://twitter.com/?lang=fr",
  },
]
