import React from "react"
import {useTranslation} from "react-i18next";
import style from "./politic-title.module.scss";

export default function PoliticTitle() {
    const {t} = useTranslation();
    return (
        <h1 className={style.politicTitle}>{t("politic.title")}</h1>
    )
}
