import PropTypes from "prop-types";
import {socialPropstype} from "./social.propstype";

export const profilePropstype =
    PropTypes.shape({
        name: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        imageNoBorder: PropTypes.string.isRequired,
        socialNetworks: socialPropstype
    });
