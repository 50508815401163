export const MENU = [
  {
    id: 1,
    name: "menu.product.title",
    to: "/#product",
  },
  {
    id: 2,
    name: "menu.human.title",
    to: "/#team",
  },
  {
    id: 3,
    name: "menu.faq.title",
    to: "/faq#root",
  },
  {
    id: 4,
    name: "menu.contact.title",
    to: "/#contactus",
  },
]

export const FOOTER_MENU = [
  {
    id: 1,
    name: "Product",
    children: [
      { name: "Vision", to: "/vision" },
      { name: "Features", to: "/features" },
      { name: "Clients", to: "/clients" },
    ],
  },
  {
    id: 2,
    name: "Legal",
    children: [
      { name: "Terms", to: "/terms" },
      { name: "Privacy Policy", to: "/privacy" },
      { name: "Cookie Policy", to: "/cookie" },
    ],
  },
  {
    id: 3,
    name: "Find us ",
    children: [
      { name: "Facebook", to: "/f" },
      { name: "Instagram", to: "/i" },
      { name: "LinkedIn", to: "/l" },
    ],
  },
]
