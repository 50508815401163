import React from "react"
import style from "./card-custom.module.scss"
import { motion } from "framer-motion"
import { textVariants } from "../../features/variants"
import FMTitle from "../animation/FMTitle/FMTitle"
import { Col, Container, Row } from "react-bootstrap"
import PropTypes from "prop-types"

CardCustom.propTypes = {
    title: PropTypes.arrayOf(PropTypes.string).isRequired,
    text: PropTypes.arrayOf(PropTypes.string).isRequired,
    firstTextBigger: PropTypes.bool,
    textSpan: PropTypes.shape({
        lg: PropTypes.number,
        sm: PropTypes.number,
        xs: PropTypes.number,
    }),
}

CardCustom.defaultProps = {
    firstTextBigger: false,
    textSpan: {
        lg: 12,
        sm: 12,
        xs: 12,
    },
}

export default function CardCustom({ title, text, firstTextBigger, textSpan }) {
    const container = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 10.5,
            },
        },
    }

    const textBloc = (i, text) => {
        if (firstTextBigger && i === 0) {
            return (
                <p className={style.biggerText} key={i}>
                    {text}
                </p>
            )
        } else {
            return <p key={i}>{text}</p>
        }
    }

    return (
        <motion.div variants={container} initial="hidden" whileInView="visible" viewport={{ once: true }}>
            <Container>
                <Row>
                    <Row>
                        {title &&
                            title.map((t, i) => (
                                <Row key={i}>
                                    <FMTitle title={t} index={i} variants={textVariants} />
                                </Row>
                            ))}
                    </Row>
                </Row>
                <Row>
                    <Col className="pt-4" lg={{ span: textSpan.lg }}>
                        <div className={style.text}>{text.map((t, i) => textBloc(i, t))}</div>
                    </Col>
                </Row>
            </Container>
        </motion.div>
    )
}
