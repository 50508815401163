import React from "react"
// Components
import FMScreen from "../components/animation/FMScreen/FMScreen"
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import EeccFR from "../components/page-sections/eecc/bylang/EeccFR";
import EeccEN from "../components/page-sections/eecc/bylang/EeccEN";
import EeccTitle from "../components/page-sections/eecc/title/EeccTitle";

export default function EeccPage() {

    const {i18n} = useTranslation();

    return (
        <div className="section-border-end pb-5 mb-5">
            <Container>
                <Row>
                    <Col><EeccTitle/></Col>
                </Row>
                <Row>
                    <Col>
                        { i18n.language === "fr" && <EeccFR /> }
                        { i18n.language === "en" && <EeccEN /> }
                    </Col>
                </Row>
            </Container>

            <FMScreen/>
        </div>
    )
}
